export const preReplacementRegexPatterns = [{
    "pattern": /(, THE|, TH| TH |\bTH |\b TH\b| THE$| TH$)/gm,
    "replacementText": ""
},{
    "pattern": /( GRP | GRP, | GRP$)/gmi,
    "replacementText": " Group "
}, {
    "pattern": /( AGY | AGY, | AGCY | AGCY, | AGY$| AGCY$)/gmi,
    "replacementText": " Agency "
}, {
    "pattern": /( SVC | SVC, | SCV | SCV, | SERV | SERV, | SVC$| SCV$| SERV$)/gmi,
    "replacementText": " Service "
}, {
    "pattern": /( SVCS | SVCS, | SCVS | SCVS, | SVCS$| SCVS$)/gmi,
    "replacementText": " Services "
}, {
    "pattern": /( CORP | CORP, | CORP\. | CORP$| CORP,$| CORP\.$)/gmi,
    "replacementText": " Corp. "
}, {
    "pattern": /( ASSN | ASSN, | ASSN\. | ASSN$| ASSN,$| ASSN\.$)/gmi,
    "replacementText": " Assn. "
}, {
    "pattern": /( ASSOC | ASSOC, | ASSOC\. | ASSC | ASSC, | ASSC\. | ASSOC$| ASSOC,$| ASSOC\.$| ASSC$| ASSC,$| ASSC\.$)/gmi,
    "replacementText": " Assoc. "
}, {
    "pattern": /( MGMT | MGMT, | MGMT\. | MGMT$)/gmi,
    "replacementText": " Management "
}, {
    "pattern": /( UND | UND, | UND\. | UND$)/gmi,
    "replacementText": " Underwriters "
}, {
    "pattern": /( BROS | BROS, | BROS\. | BROS$)/gmi,
    "replacementText": " Bros. "
}, {
    "pattern": /( INC\. | INC, | INC | INC\.$| INC,$| INC$| IN$)/gmi,
    "replacementText": " Inc. "
}, {
    "pattern": /( INS\. | INS, | INS | INS\.$| INS,$| INS$)/gmi,
    "replacementText": " Ins. "
}, {
    "pattern": /( ST | ST\. | ST, | ST\.$| ST,$| ST$)/gmi,
    "replacementText": " St. "
}, {
    "pattern": /( JR | JR\. | JR, | JR\.$| JR,$| JR$)/gmi,
    "replacementText": " Jr. "
}, {
    "pattern": /( DIV | DIV\. | DIV, | DIV\.$| DIV,$| DIV$)/gmi,
    "replacementText": " Div. "
}, {
    "pattern": /( LTD | LTD\. | LTD, | LTD\.$| LTD,$| LTD$)/gmi,
    "replacementText": " Ltd. "
}, {
    "pattern": /( CTR | CTR\. | CTR, | CTR\.$| CTR,$| CTR$)/gmi,
    "replacementText": " Ctr. "
}, {
    "pattern": /( INV | INV\. | INV, | INV\.$| INV,$| INV$)/gmi,
    "replacementText": " Inv. "
}, {
    "pattern": /( LTD | LTD\. | LTD, | LTD\.$| LTD,$| LTD$)/gmi,
    "replacementText": " Ltd. "
}, {
    "pattern": /( FIN | FIN, | FIN\. | FINL | FINL, | FINL\. | FIN$| FINL$)/gmi,
    "replacementText": " Fin. "
}, {
    "pattern": /( CORP | CORP, | CORP\. | CORP$| CORP,$| CORP\.$)/gmi,
    "replacementText": " Corp. "
}, {
    "pattern": /( INTL | INTL, | INTL\. | INTL$| INTL,$| INTL\.$)/gmi,
    "replacementText": " Intl. "
}, {
    "pattern": /\bLA DUE$/gmi,
    "replacementText": "La Due "
}, {
    "pattern": /\bLA FAY$/gmi,
    "replacementText": "La Fay "
}, {
    "pattern": /'S/gmi,
    "replacementText": "'s"
}];

export const postReplacementRegexPatterns = [{
    "pattern": /( LLC\. | LLC, | LLC | LLC\.$| LLC,$| LLC$)/gmi,
    "replacementText": " LLC "
}, {
    "pattern": /( LLP\. | LLP, | LLP | LLP\.$| LLP,$| LLP$)/gmi,
    "replacementText": " LLP "
}, {
    "pattern": /( LTC\. | LTC, | LTC | LTC\.$| LTC,$| LTC$)/gmi,
    "replacementText": " LTC "
}, {
    "pattern": /( LT\. | LT, | LT | LT\.$| LT,$| LT$)/gmi,
    "replacementText": " LT "
}, {
    "pattern": /( LL\. | LL, | LL | LL\.$| LL,$| LL$)/gmi,
    "replacementText": " LL "
}, {
    "pattern": /( BSB\. | BSB, | BSB | BSB\.$| BSB,$| BSB$)/gmi,
    "replacementText": " BSB "
}];

export const knownShortWords = [
    "ABBY",
    "ABE",
    "ABEL",
    "ABIE",
    "ABLE",
    "ACE",
    "ACES",
    "ACEY",
    "ACIE",
    "ADAH",
    "ADAM",
    "ADAN",
    "ADDA",
    "ADEL",
    "ADEN",
    "ADIN",
    "AIDA",
    "AILA",
    "AILI",
    "AJAY",
    "AL",
    "ALL",
    "ALAN",
    "ALBA",
    "ALBY",
    "ALDA",
    "ALDO",
    "ALEC",
    "ALEK",
    "ALEX",
    "ALIA",
    "ALLA",
    "ALLY",
    "ALMA",
    "ALTA",
    "ALTO",
    "ALVA",
    "ALVY",
    "ALYS",
    "AMEY",
    "AMIA",
    "AMIE",
    "AMIL",
    "AMIN",
    "AMIR",
    "AMIT",
    "AMMA",
    "AMON",
    "AMOS",
    "AMY",
    "AMYA",
    "ANDI",
    "ANDY",
    "ANIE",
    "ANN",
    "ANNA",
    "ANNE",
    "ANYA",
    "ARAH",
    "ARBA",
    "ARCH",
    "ARES",
    "ARIA",
    "ARIC",
    "ARIE",
    "ARLA",
    "ARLO",
    "ARLY",
    "ARNE",
    "ARNO",
    "ARON",
    "ARRA",
    "ART",
    "ARTA",
    "ARVO",
    "ARYA",
    "ASHA",
    "ASIA",
    "ASON",
    "ATHA",
    "AUDY",
    "AURA",
    "AUTO",
    "AVAH",
    "AVIE",
    "AVIS",
    "AVON",
    "AXEL",
    "AYAN",
    "AYLA",
    "AYVA",
    "AZUL",
    "BABE",
    "BABY",
    "BAIL",
    "BAIN",
    "BALL",
    "BAMA",
    "BANE",
    "BANK",
    "BARB",
    "BARR",
    "BART",
    "BAUM",
    "BAY",
    "BEAR",
    "BEAU",
    "BEBE",
    "BECK",
    "BEDA",
    "BEE",
    "BELK",
    "BELL",
    "BEN",
    "BENA",
    "BERT",
    "BESS",
    "BEST",
    "BETH",
    "BIG",
    "BILL",
    "BINA",
    "BING",
    "BINH",
    "BIRD",
    "BIRT",
    "BLAS",
    "BLUE",
    "BO",
    "BOB",
    "BODE",
    "BOGE",
    "BOH",
    "BOLT",
    "BOND",
    "BORN",
    "BOSE",
    "BOSS",
    "BOST",
    "BOSU",
    "BOX",
    "BOYD",
    "BRAD",
    "BRAE",
    "BRAU",
    "BREA",
    "BREE",
    "BRET",
    "BREW",
    "BRIA",
    "BROX",
    "BRY",
    "BRYN",
    "BUCK",
    "BUDD",
    "BUEL",
    "BULA",
    "BULL",
    "BUNA",
    "BUNK",
    "BURG",
    "BURI",
    "BURK",
    "BURL",
    "BURR",
    "BURT",
    "BUSH",
    "BUTZ",
    "BUY",
    "BYER",
    "BYRD",
    "CABE",
    "CADE",
    "CAEL",
    "CAEN",
    "CAIN",
    "CAL",
    "CALE",
    "CALI",
    "CALL",
    "CAMI",
    "CAMP",
    "CAPE",
    "CARA",
    "CARE",
    "CARI",
    "CARL",
    "CARO",
    "CARY",
    "CASA",
    "CASE",
    "CASH",
    "CASS",
    "CATO",
    "CAVE",
    "CEIL",
    "CEN",
    "CENA",
    "CHAD",
    "CHAS",
    "CHAZ",
    "CHE",
    "CHER",
    "CHET",
    "CHIN",
    "CHIP",
    "CIJI",
    "CITY",
    "CLAY",
    "CLEM",
    "CLEO",
    "CLOE",
    "CLUB",
    "COBY",
    "CODI",
    "CODY",
    "COEN",
    "COLE",
    "COLT",
    "COMO",
    "CONG",
    "COOK",
    "COON",
    "CORA",
    "CORE",
    "CORI",
    "CORY",
    "COST",
    "COTE",
    "COTY",
    "COUP",
    "COX",
    "CREW",
    "CRIS",
    "CROW",
    "CRUZ",
    "CUBA",
    "CUPO",
    "CURT",
    "DADE",
    "DAJA",
    "DALE",
    "DAN",
    "DANA",
    "DANE",
    "DANI",
    "DANN",
    "DANT",
    "DARA",
    "DARL",
    "DASH",
    "DAVE",
    "DAVY",
    "DAWN",
    "DAY",
    "DAYS",
    "DEAL",
    "DEAN",
    "DEBI",
    "DECK",
    "DEER",
    "DEJA",
    "DEL",
    "DELE",
    "DELL",
    "DEMA",
    "DEMI",
    "DENA",
    "DEON",
    "DERL",
    "DESI",
    "DIAN",
    "DIAZ",
    "DICK",
    "DICY",
    "DIEL",
    "DIEM",
    "DIER",
    "DILL",
    "DINA",
    "DINK",
    "DINO",
    "DION",
    "DIOR",
    "DIRK",
    "DIYA",
    "DOCK",
    "DOLA",
    "DOLL",
    "DON",
    "DONA",
    "DONN",
    "DOOR",
    "DORA",
    "DORI",
    "DORR",
    "DOSS",
    "DOTT",
    "DOTY",
    "DOUG",
    "DOVE",
    "DOWN",
    "DRAR",
    "DREW",
    "DRY",
    "DUCE",
    "DUFF",
    "DUKE",
    "DWAN",
    "DYAN",
    "DYER",
    "EADY",
    "EARL",
    "EARP",
    "EBBA",
    "EBEN",
    "EBER",
    "ECHO",
    "ED",
    "EDD",
    "EDDY",
    "EDEN",
    "EDGE",
    "EDIE",
    "EDLA",
    "EDNA",
    "EDRA",
    "EFFA",
    "EGAN",
    "EINO",
    "ELAM",
    "ELBA",
    "ELBY",
    "ELDA",
    "ELEX",
    "ELIA",
    "ELIE",
    "ELIN",
    "ELK",
    "ELLA",
    "ELLE",
    "ELMA",
    "ELMO",
    "ELNA",
    "ELON",
    "ELOY",
    "ELSA",
    "ELSE",
    "ELTA",
    "ELVA",
    "ELZA",
    "ELZY",
    "EMIL",
    "EMIR",
    "EMIT",
    "EMMA",
    "EMMY",
    "EMRY",
    "ENID",
    "ENOS",
    "ENZO",
    "EOLA",
    "ERA",
    "ERBY",
    "ERIC",
    "ERIE",
    "ERIK",
    "ERIN",
    "ERIS",
    "ERLA",
    "ERLE",
    "ERMA",
    "ERNA",
    "ERYN",
    "ESAU",
    "ESCO",
    "ESME",
    "ESSA",
    "ESTA",
    "ETHA",
    "ETNA",
    "ETTA",
    "EULA",
    "EUNA",
    "EURA",
    "EVAN",
    "EVER",
    "EVES",
    "EVIA",
    "EVIE",
    "EVON",
    "EWEN",
    "EWIN",
    "EXIE",
    "EYE",
    "EZRA",
    "FAIR",
    "FALK",
    "FALL",
    "FAR",
    "FARM",
    "FARR",
    "FAST",
    "FATE",
    "FAWN",
    "FAY",
    "FAYE",
    "FEE",
    "FERD",
    "FERN",
    "FIFE",
    "FILS",
    "FINN",
    "FIRM",
    "FISS",
    "FIVE",
    "FL",
    "FLAG",
    "FLEM",
    "FLOR",
    "FLOY",
    "FOCH",
    "FOG",
    "FOLZ",
    "FONK",
    "FORD",
    "FORT",
    "FOUR",
    "FOX",
    "FRAN",
    "FRED",
    "FREE",
    "FREY",
    "FRYE",
    "FT",
    "FULL",
    "GABE",
    "GAEL",
    "GAGE",
    "GAIL",
    "GALE",
    "GARY",
    "GAY",
    "GAYE",
    "GENA",
    "GENE",
    "GENO",
    "GERI",
    "GET",
    "GIBB",
    "GIGI",
    "GIL",
    "GILL",
    "GINA",
    "GINO",
    "GLEN",
    "GO",
    "GOEN",
    "GOFF",
    "GOLD",
    "GOSS",
    "GRAY",
    "GREG",
    "GREY",
    "GULF",
    "GUS",
    "GUSS",
    "GUST",
    "GUY",
    "GUYS",
    "GWEN",
    "GWYN",
    "HAAS",
    "HAL",
    "HALE",
    "HALI",
    "HALL",
    "HALO",
    "HAMP",
    "HAN",
    "HANA",
    "HANK",
    "HANN",
    "HANS",
    "HARL",
    "HARM",
    "HART",
    "HASH",
    "HAWK",
    "HAYS",
    "HEAD",
    "HEDY",
    "HEIL",
    "HERB",
    "HESS",
    "HIG",
    "HIGH",
    "HILL",
    "HILS",
    "HITE",
    "HOAI",
    "HOGE",
    "HOKE",
    "HOME",
    "HONG",
    "HOOD",
    "HOPE",
    "HOWE",
    "HOYT",
    "HUEY",
    "HUFF",
    "HUGH",
    "HUGO",
    "HUI",
    "HULL",
    "HUNG",
    "HUNT",
    "HUPE",
    "HUTH",
    "HYDE",
    "ICEY",
    "ICIE",
    "IKER",
    "ILAH",
    "ILDA",
    "ILLA",
    "ILMA",
    "INES",
    "INEZ",
    "INGA",
    "IOLA",
    "IONA",
    "IONE",
    "IRIS",
    "IRMA",
    "IRON",
    "IRVA",
    "ISAI",
    "ISAM",
    "ISIS",
    "ISLA",
    "ISOM",
    "ISON",
    "IT",
    "IVAH",
    "IVAN",
    "IVER",
    "IVEY",
    "IVIE",
    "IVOR",
    "IZEN",
    "JACE",
    "JACK",
    "JACO",
    "JADA",
    "JADE",
    "JAEL",
    "JAIR",
    "JAKE",
    "JAME",
    "JAMI",
    "JAN",
    "JANA",
    "JANE",
    "JANN",
    "JASE",
    "JAX",
    "JAXX",
    "JAY",
    "JAYE",
    "JEAN",
    "JEFF",
    "JENA",
    "JENS",
    "JERE",
    "JERI",
    "JESS",
    "JETT",
    "JILL",
    "JIM",
    "JING",
    "JO",
    "JOAN",
    "JOBE",
    "JODI",
    "JODY",
    "JOE",
    "JOEL",
    "JOEY",
    "JOHN",
    "JON",
    "JONI",
    "JORY",
    "JOSE",
    "JOSH",
    "JOY",
    "JOYE",
    "JUAN",
    "JUDD",
    "JUDE",
    "JUDI",
    "JUDY",
    "JULE",
    "JULI",
    "JUNE",
    "JUNG",
    "JUST",
    "JUUL",
    "KACE",
    "KACI",
    "KACY",
    "KADE",
    "KAEL",
    "KAHN",
    "KAIA",
    "KALA",
    "KALE",
    "KALI",
    "KAMI",
    "KANE",
    "KARA",
    "KARI",
    "KARL",
    "KASE",
    "KASH",
    "KATE",
    "KATI",
    "KATY",
    "KATZ",
    "KAVE",
    "KAY",
    "KAYA",
    "KAYE",
    "KEEN",
    "KEEP",
    "KELI",
    "KEN",
    "KENT",
    "KEON",
    "KERI",
    "KERN",
    "KERR",
    "KEY",
    "KIAN",
    "KIEL",
    "KIM",
    "KIME",
    "KING",
    "KIPP",
    "KIRA",
    "KIRK",
    "KIRT",
    "KISH",
    "KITE",
    "KIYA",
    "KLOE",
    "KNOB",
    "KNOX",
    "KOBE",
    "KOBY",
    "KOCH",
    "KODA",
    "KODY",
    "KOEN",
    "KOLB",
    "KOLE",
    "KORA",
    "KORI",
    "KORY",
    "KREW",
    "KRIS",
    "KURT",
    "KWAN",
    "KYAN",
    "KYLA",
    "KYLE",
    "KYLO",
    "KYNG",
    "KYRA",
    "KYRO",
    "LACI",
    "LACY",
    "LADY",
    "LAFE",
    "LAKE",
    "LALA",
    "LAMB",
    "LAMI",
    "LANA",
    "LAND",
    "LANE",
    "LANG",
    "LANI",
    "LARA",
    "LARK",
    "LARS",
    "LARY",
    "LAS",
    "LAW",
    "LAZO",
    "LAZY",
    "LC",
    "LE",
    "LEA",
    "LEAF",
    "LEAH",
    "LEAR",
    "LEDA",
    "LEE",
    "LEET",
    "LEHR",
    "LEIA",
    "LEIF",
    "LELA",
    "LENA",
    "LEO",
    "LEON",
    "LERA",
    "LESA",
    "LESS",
    "LETA",
    "LEVA",
    "LEVI",
    "LEVY",
    "LEXI",
    "LEY",
    "LEYH",
    "LI",
    "LIAM",
    "LIAN",
    "LIDA",
    "LIEM",
    "LIFE",
    "LIGE",
    "LILA",
    "LILY",
    "LIN",
    "LINA",
    "LIND",
    "LINE",
    "LINK",
    "LINN",
    "LION",
    "LISA",
    "LISE",
    "LISH",
    "LITA",
    "LIU",
    "LIVE",
    "LIZA",
    "LOAN",
    "LOCK",
    "LODA",
    "LOFT",
    "LOIS",
    "LOLA",
    "LOMA",
    "LONA",
    "LONE",
    "LONG",
    "LONI",
    "LOOK",
    "LORA",
    "LORI",
    "LOTA",
    "LOTT",
    "LOU",
    "LOVE",
    "LOW",
    "LOY",
    "LOYD",
    "LUCA",
    "LUCY",
    "LUDA",
    "LUIS",
    "LUKA",
    "LUKE",
    "LULA",
    "LULU",
    "LUNA",
    "LUPE",
    "LURA",
    "LUTE",
    "LUTH",
    "LYDA",
    "LYLA",
    "LYLE",
    "LYNN",
    "LYRA",
    "MACE",
    "MACI",
    "MACK",
    "MACY",
    "MAE",
    "MAI",
    "MAIA",
    "MAIN",
    "MALE",
    "MALL",
    "MAME",
    "MAN",
    "MANG",
    "MANN",
    "MARA",
    "MARC",
    "MARI",
    "MARK",
    "MART",
    "MARY",
    "MASS",
    "MATH",
    "MATT",
    "MAUD",
    "MAX",
    "MAXX",
    "MAYA",
    "MAYE",
    "MAYO",
    "MAYS",
    "MEDA",
    "MEGA",
    "MELL",
    "MENA",
    "MERL",
    "META",
    "MIAH",
    "MID",
    "MIKE",
    "MILA",
    "MILE",
    "MILK",
    "MILO",
    "MIMA",
    "MIMI",
    "MINA",
    "MIRA",
    "MIX",
    "MIYA",
    "MONA",
    "MONT",
    "MONY",
    "MORA",
    "MOSE",
    "MOSS",
    "MURL",
    "MUSA",
    "MUSE",
    "MY",
    "MYAH",
    "MYER",
    "MYLA",
    "MYLO",
    "MYRA",
    "MYRL",
    "NADA",
    "NALA",
    "NANA",
    "NASH",
    "NAVY",
    "NAYA",
    "NEAL",
    "NED",
    "NEEL",
    "NEFF",
    "NEHA",
    "NEIL",
    "NELL",
    "NELS",
    "NENA",
    "NERY",
    "NETA",
    "NEVA",
    "NEW",
    "NEWT",
    "NEXT",
    "NHAN",
    "NHUY",
    "NICK",
    "NICO",
    "NIKI",
    "NIKO",
    "NILA",
    "NILE",
    "NILS",
    "NINA",
    "NIRA",
    "NITA",
    "NOAH",
    "NOEL",
    "NOLA",
    "NOMA",
    "NONA",
    "NOOR",
    "NORA",
    "NOVA",
    "NOYD",
    "NYAH",
    "NYLA",
    "OAK",
    "OBED",
    "OBIE",
    "OCIE",
    "OCTA",
    "ODIE",
    "ODIN",
    "ODIS",
    "ODUS",
    "OHIO",
    "OKEY",
    "OLAF",
    "OLAN",
    "OLAR",
    "OLD",
    "OLDE",
    "OLEN",
    "OLEY",
    "OLGA",
    "OLIE",
    "OLIN",
    "OLOF",
    "OMAR",
    "OMER",
    "OMIE",
    "ONE",
    "ONEY",
    "ONIE",
    "ONYX",
    "OPAL",
    "OPEN",
    "OPHA",
    "ORAH",
    "ORAL",
    "ORAN",
    "ORCA",
    "OREN",
    "ORIE",
    "ORIN",
    "ORIS",
    "ORLA",
    "ORLO",
    "ORMA",
    "ORRA",
    "OSIE",
    "OTHA",
    "OTHO",
    "OTIS",
    "OTTO",
    "OVID",
    "OWEN",
    "OWL",
    "OYER",
    "OZIE",
    "PAGE",
    "PALM",
    "PARK",
    "PAT",
    "PATE",
    "PATH",
    "PAUL",
    "PEAK",
    "PECK",
    "PETE",
    "PHIL",
    "PIKE",
    "PINK",
    "PLES",
    "POLK",
    "POND",
    "POPE",
    "PORT",
    "POST",
    "PRO",
    "PURL",
    "QUOC",
    "QUY",
    "RAJ",
    "RAFE",
    "RAHN",
    "RAIN",
    "RAMS",
    "RAND",
    "RANT",
    "RAUL",
    "RAY",
    "REAL",
    "REBA",
    "RED",
    "REED",
    "REID",
    "REMI",
    "REMY",
    "RENA",
    "RENE",
    "RENO",
    "RETA",
    "REVA",
    "RHEA",
    "RHYS",
    "RIAN",
    "RICE",
    "RICH",
    "RICK",
    "RICO",
    "RIO",
    "RISA",
    "RISE",
    "RISK",
    "RITA",
    "RIYA",
    "ROB",
    "ROBB",
    "ROBT",
    "ROBY",
    "ROCK",
    "ROD",
    "ROE",
    "ROEL",
    "ROLF",
    "ROLL",
    "ROMA",
    "ROME",
    "RON",
    "RONA",
    "RONI",
    "RORY",
    "ROSA",
    "ROSE",
    "ROSS",
    "ROSY",
    "ROXY",
    "ROY",
    "RUBE",
    "RUBI",
    "RUBY",
    "RUDD",
    "RUDY",
    "RUE",
    "RUEL",
    "RUHL",
    "RUIE",
    "RUSH",
    "RUSS",
    "RUTH",
    "RYAN",
    "RYNE",
    "SADA",
    "SADE",
    "SAFE",
    "SAGE",
    "SALT",
    "SAM",
    "SAMS",
    "SARA",
    "SAUL",
    "SCOT",
    "SEAN",
    "SENA",
    "SETH",
    "SHAD",
    "SHAE",
    "SHAN",
    "SHAW",
    "SHAY",
    "SHEA",
    "SHEP",
    "SHIH",
    "SHON",
    "SIMI",
    "SIMS",
    "SINA",
    "SING",
    "SKIP",
    "SKY",
    "SKYE",
    "SON",
    "SONU",
    "STAN",
    "STAR",
    "SULA",
    "SUN",
    "SUZY",
    "SYED",
    "TAFT",
    "TAHJ",
    "TAJA",
    "TAKE",
    "TAMI",
    "TANA",
    "TARA",
    "TARI",
    "TATE",
    "TAYA",
    "TEAM",
    "TED",
    "TENA",
    "TERA",
    "TERI",
    "TESS",
    "THAD",
    "THAT",
    "THEA",
    "THEO",
    "THOR",
    "THOS",
    "TIDE",
    "TIER",
    "TIM",
    "TIME",
    "TINA",
    "TINY",
    "TISA",
    "TITO",
    "TOBE",
    "TOBI",
    "TOBY",
    "TOD",
    "TODD",
    "TOM",
    "TOMA",
    "TONI",
    "TONY",
    "TOP",
    "TORI",
    "TORY",
    "TOWN",
    "TOYA",
    "TRAE",
    "TREE",
    "TREY",
    "TROY",
    "TUAN",
    "TULA",
    "TYRA",
    "VADA",
    "VARA",
    "VEDA",
    "VELA",
    "VENA",
    "VERA",
    "VERE",
    "VERL",
    "VERN",
    "VETA",
    "VEVA",
    "VICK",
    "VICY",
    "VIDA",
    "VINA",
    "VIRA",
    "VITA",
    "VITO",
    "VIVA",
    "WADE",
    "WALT",
    "WARD",
    "WASH",
    "WATT",
    "WAVA",
    "WE",
    "WEBB",
    "WESS",
    "WEST",
    "WHIT",
    "WILL",
    "WIN",
    "WINE",
    "WING",
    "WIRT",
    "WONG",
    "WOOD",
    "WREN",
    "XAVI",
    "XENA",
    "YAEL",
    "YAIR",
    "YALE",
    "YARA",
    "YOEL",
    "YORK",
    "YOU",
    "YOUR",
    "ZACK",
    "ZADA",
    "ZAID",
    "ZAIN",
    "ZANA",
    "ZANE",
    "ZARA",
    "ZAYD",
    "ZAYN",
    "ZEKE",
    "ZELA",
    "ZENA",
    "ZENO",
    "ZETA",
    "ZINA",
    "ZION",
    "ZITA",
    "ZOEY",
    "ZOIE",
    "ZOLA",
    "ZONA",
    "ZORA",
    "ZULA",
    "ZURI",
    "ZYON"
];

export const stateAbbreviation = [
    "CA",
    "GA",
    "AK",
    "AR",
    "AZ",
    "CO",
    "CT",
    "CT",
    "CU",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "RV",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
    "IL",
    "IL",
    "IN",
    "IN",
    "KS",
    "KY",
    "LA"
];
