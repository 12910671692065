import { useState } from "react";
import { LogoSafecoHoriz, Button, Link, Disclaimer } from "@lmig/lmds-react";
import { Constants } from "../../../helpers/Constants";
import Dropdown from "./Dropdown/Dropdown";
import InfoCard from "../InfoCard/InfoCard";
import "./RegularHeader.css";

function RegularHeader(props: JSX.Element[]) {
  const [activeTab, setIsActiveTab] = useState<string>("");

  return (
    <div id="header-nav-bar">
      <a href="https://safeco.com">
        <LogoSafecoHoriz />
      </a>
      <button
        onClick={() => setIsActiveTab("shopInsurance")}
        className={activeTab === "shopInsurance" ? "header-button header-active-tab" : "header-button"}>
        Shop insurance
        {activeTab === "shopInsurance" ? <Dropdown
          closeSelf={() => setIsActiveTab("")}
          categories={Constants.MegaMenus.ShopInsurance.Sections}
          buttonInfo={Constants.MegaMenus.ShopInsurance.Button}>
          <InfoCard title={"Safe drivers save with RightTrack®"}>
            <a href="https://www.safeco.com/products/righttrack">
              <Button>Learn how</Button>
            </a>
          </InfoCard>
        </Dropdown> : null}
      </button>
      <button
        onClick={() => setIsActiveTab("forCustomers")}
        className={activeTab === "forCustomers" ? "header-button header-active-tab" : "header-button"}>
        For customers
        {activeTab === "forCustomers" ? <Dropdown
          closeSelf={() => setIsActiveTab("")}
          categories={Constants.MegaMenus.ForCustomers.Sections}
          buttonInfo={Constants.MegaMenus.ForCustomers.Button}>
          <InfoCard title="Get Roadside Assistance*">
            <>
              <Link href="tel:1-877-762-3101" variant="standalone" caretPosition="none" style={{ marginBottom: "0.75rem" }}>
                1-877-762-3101
              </Link>
              <Disclaimer>
                *Additional charges apply if your policy does not include the Towing and
                Labor coverage.
              </Disclaimer>
            </>
          </InfoCard>
        </Dropdown> : null}
      </button>
      <button
        onClick={() => setIsActiveTab("forAgents")}
        className={activeTab === "forAgents" ? "header-button header-active-tab" : "header-button"}>
        For agents
        {activeTab === "forAgents" ? <Dropdown
          closeSelf={() => setIsActiveTab("")}
          categories={Constants.MegaMenus.ForAgents.Sections}
          buttonInfo={Constants.MegaMenus.ForAgents.Button}>
          <InfoCard title="Apply to sell Safeco Insurance" content="We offer unique product lines and competitive commisions.">
            <a href="https://www.safeco.com/agent-resources/apply"><Button>Apply now</Button></a>
          </InfoCard>
        </Dropdown> : null}
      </button>
      <a
        href="https://customer.safeco.com/accountmanager/account/login"
        target="_blank"
        rel="noreferrer"
        id="header-login-wrapper"
      >
        <Button id="header-login" size="small">
          Log in
        </Button>
      </a>
    </div>
  );
}

export default RegularHeader;
