import { Heading, IconCaretCircleLeft, IconCaretCircleRight, Link, IconButton, Modal, GridCol, GridRow} from "@lmig/lmds-react";
import { languages } from "../../../helpers/LanguageHelper";
import Products from "../../Products/Products";
import "./AboutSection.css";
import { useState} from "react";

function AboutSection(props: IAboutSection) {
  let [photoModal, setPhotoModal] = useState<{ isOpen: boolean, activeIndex: number }>({ isOpen: false, activeIndex: 0 });
  let carriers = props.carriers;
  const [galleryLoaded, setGalleryLoaded] =  useState<boolean>(false);

  if(carriers){
    if(! carriers.map(e => e.carrierName).includes('Safeco')){
      carriers.unshift({carrierId: 0, carrierName: "Safeco"})
    }
  }else{
    carriers = [];
    carriers.unshift({carrierId: 0, carrierName: "Safeco"})
  }

  return (
    <>
      {/* If there is information in the middle column of agency page, show the column */}
      {props.about ||
        props.experience ||
        props.products ||
        props.carriers ||
        props.awards ||
        props.articles ||
        props.languages ||
        props.photos
        ? <Heading id="about" type="h3-light" className="about-section-heading">
            About us
          </Heading>
        : null
      }
      <div className="about-section">
        {/* show about section if present */}
        <div><p>{props.about}</p></div>
        {/* Show years of experience if present */}
        {props.experience
          ? <div>
              <Heading type="h4-light" className="about-section-heading">
                Experience
              </Heading>
              <p>{"We have been in the business since " + props.experience}</p>
            </div>
          : null
        }

        {/* show languages agency speaks if present */}
        {props.languages
          ? <div>
              <Heading type="h4-light" className="about-section-heading">
                Languages
              </Heading>
              {props.languages.filter((item,index) =>
                props.languages?.indexOf(item)===index).map((language, i) =>
                <div key={i}>{languages[language] ?? language.trim()}</div>
              )}
            </div>
          : null
        }
        {/* show product specialities if present */}
        {props.products
          ? <div>
              <Heading type="h4-light" className="about-section-heading">
                Products we sell
              </Heading>
              <Products products={props.products} />
            </div>
          : null
        }

        {/* show articles if present */}
        {props.articles
          ? <div id="about-section-articles">
              <Heading type="h4-light" className="about-section-heading">
                Read about us
              </Heading>
              <div className="about-us-list">
                {props.articles.map((article, i) => (
                  <Link
                    variant="standalone"
                    caretPosition="none"
                    href={article.articleURL}
                    target="_blank"
                    rel="noreferrer"
                    key={i}>{article.articleTitle}</Link>
                ))}
              </div>
            </div>
          : null
        }

        <div className="about-section-row">
          {/* show awards won if present */}
          {props.awards
            ? <div>
                <Heading type="h4-light" className="about-section-heading">
                  Awards
                </Heading>
                <div id="about-section-awards">
                  {props.awards.map((award,i) => (
                    <div key={i}>{award.awardName + " " + award.awardYear}</div>
                  ))}
                </div>
              </div>
            : null
          }

          {/* show affiliations if present */}
          {props.affiliations
            ? <div>
                <Heading type="h4-light" className="about-section-heading">
                  Affiliations
                </Heading>
                <div className="affiliations">
                  {props.affiliations.map((affiliation, i) =>
                    <div key={i}>{affiliation.affiliationName}</div>
                  )}
                </div>
              </div>
            : null
          }
        </div>

        {/* show carriers if present */}
        {carriers
          ? <div>
              <Heading type="h4-light" className="about-section-heading">
                Carriers
              </Heading>
              <div id="about-section-carriers">
              {carriers.map((carrier, i) => (
                <div key={i}>{carrier.carrierName}</div>
              ))}
              </div>
            </div>
          : null
        }

        {/* show agency gallery if present */}
        {props.photos
          ? <>
              <div>
                <Heading type="h4-light" className="about-section-heading">
                  Agency Photos
                </Heading>
                <GridRow gutters gutterSize="fixed-16">
                {props.photos.map((photo, index) => (
                  <GridCol>
                    <div className={galleryLoaded ? "gallery-image-container" : "gallery-image-placeholder"}>
                    <img
                      key={index}
                      className="gallery-image"
                      src={!photo.blob_id ?  photo.base64 : "/FAANG_App_Svc/resources/blobs/" + photo.blob_id.toUpperCase()}
                      alt={photo.description}
                      onClick={() => setPhotoModal({ isOpen: true, activeIndex: index })}
                      onLoad={() => setGalleryLoaded(true)}
                    />
                    </div>
                  </GridCol>
                ))}
                </GridRow>
              </div>
              <Modal
                isOpen={photoModal.isOpen}
                size="large"
                modalStyle="standard"
                closeFunc={() => setPhotoModal({ isOpen: false, activeIndex: 0 })} className="photo-gallery-modal">
                <div className="photo-gallery-modal-image">
                    {props.photos.length > 1 && <IconButton onClick={() => setPhotoModal({ isOpen: true, activeIndex: photoModal.activeIndex === 0 ? props.photos.length - 1 : photoModal.activeIndex - 1 })}><IconCaretCircleLeft /></IconButton>}
                    <img src={props.photos[photoModal.activeIndex]?.blob_id ? "/FAANG_App_Svc/resources/blobs/" + props.photos[photoModal.activeIndex].blob_id : props.photos[photoModal.activeIndex].base64} alt={props.photos[photoModal.activeIndex].description} />
                    {props.photos.length > 1 && <IconButton onClick={() => setPhotoModal({ isOpen: true, activeIndex: (photoModal.activeIndex + 1 === props.photos.length ? 0 : photoModal.activeIndex + 1) })}><IconCaretCircleRight /></IconButton>}
                </div>
                {props.photos[photoModal.activeIndex].description ? <i style={{margin: "auto", paddingTop: "20px"}}>{props.photos[photoModal.activeIndex].description}</i> : null}
                {props.photos.length > 1
                  ? <ol className="photo-indicators">
                      {props.photos.map((x, i) => <li key={i} className={photoModal.activeIndex === i ? "active" : ""} onClick={() => setPhotoModal({ isOpen: true, activeIndex: i })} />)}
                    </ol>
                  : null
                }
              </Modal>
            </>
          : null
        }
      </div>
    </>
  );
}
export default AboutSection;
