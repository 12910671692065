import "./ReviewCard.css";
import { Link, Card, IconCaretDown } from "@lmig/lmds-react";
import React, { useState } from "react";

function ReviewCard(props: google.maps.places.PlaceReview) {
    const [showSummary, setShowSummary] = useState<boolean>(false);
    return (
        <Card className="review-card">
            <div className="review-row">
                <Link variant="standalone" caretPosition="none" target="_blank" href={props.author_url} className="review-name-row">
                    <img referrerPolicy="no-referrer" src={props.profile_photo_url} alt={props.author_name} /> {/*wihtout specifying referrerpolicy, images will sometimes throw 403s*/}
                    <span className="review-top-row">
                        <div>
                            <div className="review-author-name capitalize">{props.author_name}</div>
                            <div>
                                <span className="rating stars" style={{"--rating" : props?.rating ?? 0 } as React.CSSProperties}>
                                  {props.rating?.toPrecision(2)}&nbsp;&nbsp;
                                </span>
                            </div>
                        </div>
                        <small className="review-date">{props.relative_time_description}</small>
                    </span>
                </Link>
                {props.text.length < 235 ? <div className="review-card-text">{props.text}</div> :
                    <>
                        <div className={showSummary ? "review-card-text open-summary" : "review-card-text closed-summary"}>{props.text}</div>
                        {!showSummary ? <Link onClick={() => setShowSummary(true)} variant="standalone" caretPosition="none">Show more<IconCaretDown size="16" color="teal" /></Link> :
                            <Link onClick={() => setShowSummary(false)} variant="standalone" caretPosition="none">Show less<IconCaretDown size="16" color="teal" /></Link>}
                    </>
                }
            </div>
        </Card>
    );
}
export default ReviewCard;
