import { useRef } from "react";
import LinksSubgroup from "../../../shared/LinksSubgroup/LinksSubgroup";
import { Button } from "@lmig/lmds-react";
import useOutsideClick from "../../../shared/CustomHooks/useOutsideClick";
import "./Dropdown.css";

function Dropdown(props: {
  categories: { Heading: string; Links: { label: string; url: string }[] }[];
  buttonInfo: { label: string; url: string };
  children?:React.ReactNode,
  closeSelf:() => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, props.closeSelf, true);
  return (
    <><div id="dropdown-container" ref={ref}>
        <div id="dropdown-left-side-container">
          <div id="dropdown-upper-left-container">
            <div id="dropdown-all-links-container">
              {props.categories.map((category, i) => (
                <div id="dropdown-link-subgroup-container">
                  <LinksSubgroup 
                    key={i}
                    heading={category.Heading}
                    links={category.Links}
                  />
                </div>
              ))}
            </div>
            <hr id="dropdown-line-divider" />
          </div>
          <a href={props.buttonInfo.url}>
            <Button id="dropdown-bottom-button" variant="primary">
              {props.buttonInfo.label}
            </Button>
          </a>
        </div>
        <div id="dropdown-right-side-container">
          {props.children}
        </div>
      </div>
    </>
  );
}
export default Dropdown;
