const agentLinks = [
  {
    label: "Find an agent",
    url: "https://www.safeco.com/find-an-agent",
  },
  {
    label: "Become an agent",
    url: "https://www.safeco.com/agent-resources/become-independent-agent",
  },
  {
    label: "Agents in the community",
    url: "https://www.safeco.com/agent-resources/agents-community",
  },
];

const insuranceAgents = { heading: "Insurance Agents", links: agentLinks };

export {insuranceAgents};