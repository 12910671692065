import "./App.css";
import "@lmig/lmds-react/base.css";
import HeaderContent from "./components/HeaderContent/HeaderContent";
import BottomFooterContent from "./components/FooterContent/BottomFooterContent/BottomFooterContent";
import TopFooterContent from "./components/FooterContent/TopFooterContent/TopFooterContent";
import SearchBoxContent from "./components/SearchBoxContent/SearchBoxContent";
import AgencyInfoPage from "./components/AgencyInfoPage/AgencyInfoPage";
import AgentInfoPage from "./components/AgentInfoPage/AgentInfoPage";
import { Footer } from "@lmig/lmds-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createContext, useEffect, useState } from "react"

interface IAppContext {
  place?: google.maps.places.PlaceResult;
  agency?: any;
  searchContext?: {
    results: any,
    url: string,
    page?:number,
    filter?:string
  };
  setAgency: (agency?: any) => void;
  setSearchContext: (sr?: {
    results: any,
    url: string,
    page?:number,
    filter?:string
  }) => void;
  setPlace: (place?: google.maps.places.PlaceResult) => void;
}

let defaultValue: IAppContext = {
  setAgency: (agency?: any) => { },
  setSearchContext: (sr?: {
    results: any,
    url: string,
    page?:number,
    filter?:string
  }) => { },
  setPlace: (place?: google.maps.places.PlaceResult) => { },

}
export const AppContext = createContext<IAppContext>(defaultValue);

function App() {
  const [searchContext, setSearchContext] = useState();
  const [agency, setAgency] = useState(null);
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();
  const [preview, setPreview] = useState<boolean>(false); // used for preview on faa-pm-client
  const [agent, setAgent] = useState<any>(null); // used for preview on faa-pm-client
  let appContext: IAppContext = {
    agency: agency,
    place: place,
    searchContext: searchContext,
    setAgency: (agency?: any) => { setAgency(agency) },
    setSearchContext: (sr?: any) => { setSearchContext(sr) },
    setPlace: (place?: google.maps.places.PlaceResult) => { setPlace(place); },
  };

  // used for preview on faa-pm-client
  useEffect(() => {
    window.addEventListener("message", (event) => {

      //check the origin of the post
      const previewOrigins = (window as any)?.env?.PREVIEW_ORIGINS.split(',');

      if (previewOrigins && !(previewOrigins.includes(event.origin))) {
        return;
      }
      //don't update agent/agency objects when the iframe resizer package messages us
      if (typeof event.data === "string" && (event.data.indexOf("[iFrameSizer]") >= 0 || event.data.indexOf("[iFrameResizerChild]") >= 0))
        return;

      if (event.data?.isAgentData) {
          setAgent(event.data);
      }
      else {
        setAgency(event.data)
      }

      setPreview(event.data?.preview);
  }, false);}, []);

  return (
    <div id="app" className="lm-app">
      <div id="faa-home">
        <Router>
          <AppContext.Provider value={appContext}>
            <Routes>
              <Route path="/find-an-insurance-agency" element={
                <>
                  <HeaderContent squeeze={false} />
                  <SearchBoxContent />
                </>} />
              <Route path="/find-an-insurance-agency/agency/:id" element={
              <>
                {!preview ? <HeaderContent squeeze={true} />: null}
                <AgencyInfoPage />
              </>} />
              <Route path="/find-an-insurance-agency/agency/:agencyId/agent/:agentId" element={
              <>
                {!preview ? <HeaderContent squeeze={true} /> : <></>}
                <AgentInfoPage agent={agent}/>
              </>} />
              <Route path="*" element={<>
                  <HeaderContent squeeze={false} />
                  <SearchBoxContent />
                </>} />
            </Routes>
          </AppContext.Provider>
        </Router>
        {!preview ?
          <>
            <Footer id="faa-top-footer">
              <TopFooterContent />
            </Footer>
            <Footer id="faa-bottom-footer">
              <BottomFooterContent />
            </Footer>
          </>
        : <></>}
      </div>
    </div>
  );
}

export default App;
