import LinksSubgroup from "../../../shared/LinksSubgroup/LinksSubgroup";
import {
  Accordion,
  AccordionTab,
  Button,
} from "@lmig/lmds-react";
import "./SlidePanelContent.css";

function SlidePanel(props: {
  content: {
    shopInsuranceContent: IDropdownInfo;
    forCustomersContent: IDropdownInfo;
    forAgentsContent: IDropdownInfo;
    infoCards: JSX.Element[]
  };
}) {
  return (
    <>
      <Accordion singleTab as="h6">
        <AccordionTab labelVisual={"Shop Insurance"}>
          {props.content.shopInsuranceContent.Sections.map((category :any) => (
            <LinksSubgroup
              heading={category.Heading}
              links={category.Links}
            />
          ))}
          <Button
            className="slide-panel-button"
            variant="primary"
            href={props.content.shopInsuranceContent.Button.url}>
            {props.content.shopInsuranceContent.Button.label}
          </Button>
          {props.content.infoCards[0]}
        </AccordionTab>
        <AccordionTab labelVisual={"For Customers"}>
          {props.content.forCustomersContent.Sections.map((category:any) => (
            <LinksSubgroup
              heading={category.Heading}
              links={category.Links}
            />
          ))}
          <Button
            className="slide-panel-button"
            variant="primary"
            href={props.content.forCustomersContent.Button.url}
          >
            {props.content.forCustomersContent.Button.label}
          </Button>
          {props.content.infoCards[1]}
        </AccordionTab>
        <AccordionTab labelVisual={"For Agents"}>
          {props.content.forAgentsContent.Sections.map((category:any) => (
            <LinksSubgroup
              heading={category.Heading}
              links={category.Links}
            />
          ))}
          <Button
            className="slide-panel-button"
            variant="primary"
            href={props.content.forAgentsContent.Button.url}
          >
            {props.content.forAgentsContent.Button.label}
          </Button>
          <div className="slide-panel-info-card-wrapper">
          {props.content.infoCards[2]}
          </div>
        </AccordionTab>
      </Accordion>
    </>
  );
}
export default SlidePanel;
