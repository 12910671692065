const getAgencyInfo = async (
    id: string | null,
  ) => {
    let pathPrefix = "/FAANG_App_Svc/resources/agencyProfile/details?";
    let path = pathPrefix;
    if (id) {
      path = `${pathPrefix}id=${id}`;
    }
    else{
        console.log("error: agency did not have id")
    }
    return await fetch(path, {
      method: "GET",
    }).then((res) => {
      if (res.statusText === "Internal Server Error" || res.statusText === "No Content")
        return res;
      else
        return res.json();
    }).catch(() => {                        // catch
      return {statusText:"Internal Server Error"};
    });
  };
  export default getAgencyInfo;