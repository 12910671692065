import {
  Alert, Card, Select, SelectOption, Heading, Form, FieldGroup,
  AlphanumericInput, Link, Button, GridRow, GridCol, IconButton,
  IconCaretCircleLeft, IconCaretCircleRight, IconClose
} from "@lmig/lmds-react";
import "./SearchBoxContent.css";
import { useState, useEffect, useRef, useContext } from "react";
import AgencyCard from "../AgencyCard/AgencyCard";
import { states } from "../../helpers/StateHelper";
import getSearchResults from "../../apiCalls/getSearchResults";
import getEliteStatus from "../../apiCalls/getEliteStatus";
import ReactDOMServer from "react-dom/server";
import AgencyInfoModal from "../AgencyInfoModal/AgencyInfoModal";
import { fixCase, trimZipCode, formatSearchBoxValue, formatAgencyName } from "../../helpers/StringFormatter"
import { languages } from "../../helpers/LanguageHelper"
import ReactDOM from "react-dom";
import LandingPageContent from "../LandingPageContent/LandingPage";
import { Constants } from "../../helpers/Constants"
import { AppContext } from "../../App";
import { CircularProgressSpinner } from "@lmig/sales-ui-components";
import { Helmet, HelmetProvider} from 'react-helmet-async';

function SearchBoxContent() {
  const google = window.google;
  const [mapMarkers, setMapMarkers] = useState<{ [key: string]: google.maps.Marker }>({});
  const searchBoxMapRef = useRef<any>();
  const resultsPerPage = 10;

  /*search terms and results*/
  const [searchParams, setSearchParams] = useState<ISearchTerms>({ name: '', location: { term: '' } });
  const [urlParams, setUrlParams] = useState<ISearchTerms>({ name: '', location: { term: '' } });
  const locationSearchTermBounds = useRef<google.maps.LatLng>();

  const results = useRef([] as any[]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [hideLandingPageContent, setHideLandingPageContent] = useState<boolean>(false);

  const nameInput = useRef<any>();
  const locationInput = useRef<any>();

  /*app context */
  const context = useContext(AppContext);
  /*language filter*/
  const [language, setLanguage] = useState("");
  const [filteredResults, setFilteredResults] = useState([] as any[]);

  /*pagination*/
  const [currentPage, setCurrentPage] = useState(1);
  const [nearByPages, setNearbyPages] = useState([1])

  let lastClickedMarker: google.maps.Marker = new google.maps.Marker();
  (window as any).zIndexIncrementor = 300;
  let autocomplete: google.maps.places.Autocomplete; //developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  const newMap = useRef<boolean>(true);
  let map: google.maps.Map;  //https://developers.google.com/maps/documentation/javascript/places
  let overLay: google.maps.OverlayView;
  let projection: google.maps.MapCanvasProjection;
  const mapBounds = useRef<google.maps.LatLngBounds>();
  const mapWidth = useRef<number>(0);
  const mapHeight = useRef<number>(0);
  const panSearchThresholdMet = useRef<boolean>(false);
  const autoSearch = useRef<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const SearchControlPosition = useRef<number>(-1);

  const infowindow: google.maps.InfoWindow = new google.maps.InfoWindow();
  const maxWidthTablet = 768;
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setViewPortWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize)
  }, [])

  //initializes google autocomplete, read search terms from url
  useEffect(() => {
    initAutocomplete();
    let searchParams = new URL(window.location.href).searchParams
    if (searchParams.get("name") || searchParams.get("location"))
      setUrlParams(interpretSearch(searchParams.get("name") || "", searchParams.get("location") || ""));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //executes a search when url search params change, updates url to be in sync with search term
  useEffect(() => {
    if (urlParams?.name || urlParams?.location.term) {
      let newPath: string = `${window.location.origin}/find-an-insurance-agency`;
      if (urlParams.location?.term && urlParams.name) {
        newPath += `?name=${urlParams.name}&location=${urlParams.location.term}`;
        window.history.pushState({ path: newPath }, "", newPath);
      }
      else if (urlParams.name) {
        newPath += `?name=${urlParams.name}`;
        window.history.pushState({ path: newPath }, "", newPath);
      }
      else if (urlParams.location) {
        newPath += `?location=${urlParams.location.term}`;
        window.history.pushState({ path: newPath }, "", newPath);
      }

      //update browser tab title with search term
      document.title = `${urlParams?.name
        ? `Find Agents: ${urlParams.name}`
        : (urlParams?.location?.term
            ? `Find Agents: ${urlParams?.location?.term }`
            : `Find Agents`
          )
      }`;

      setSearchParams(urlParams);
      setHideLandingPageContent(true);

      if (context.searchContext?.results && window.location.search === context.searchContext?.url)
        results.current = context.searchContext?.results;
      else
        findAgencies();

      if (context.searchContext?.filter)
        setLanguage(context.searchContext?.filter);
      if (context.searchContext?.page)
        setCurrentPage(context.searchContext?.page);
    }
  }, [urlParams]);  // eslint-disable-line react-hooks/exhaustive-deps

  //setup pagination when results, the result page, or the language filter change
  useEffect(() => {
    let langArray: string[] = [];
    function setPagination(cp: number = currentPage) {
      let array: number[] = [];
      let lp: number = Math.ceil((language ? langArray : results.current).length / resultsPerPage);
      if (lp <= 5 || cp < 4) { /* 1 2 3 4 5 or 1 2 3 4...10 */
        for (let i = 1; i < Math.min(5, lp); i++)
          array.push(i);
        array.push(lp);
      }
      else if (cp - 2 > 1) { /* 1 ...3 4 5...10 */
        array.push(1)
        array.push(cp - 1)
        array.push(cp)
        array.push(cp + 1)
        array.push(lp);
      }
      else if (lp - 2 < cp) { /* 1...7 8 9 10 */
        array.push(1)
        array.push(lp - 3)
        array.push(lp - 2)
        array.push(lp - 1)
        array.push(lp);
      }
      setNearbyPages(array);

    }
    if (language) {
      let eliteAgencies: any[3] = [];
      for (let i = 0; i < results.current.length; i++) {
        if (results.current[i].languages)
          if (results.current[i].languages.includes(language)) {
            if (eliteAgencies.length < 3 && results.current[i].elite)
              eliteAgencies.push(results.current[i]);
            else
              langArray.push(results.current[i]);
          }
      }
      setCurrentPage(1);
      setFilteredResults(eliteAgencies.concat(langArray));
    }
    setPagination();
    renderMap();
  }, [currentPage, results.current, language]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    renderMap();
  }, [filteredResults])  // eslint-disable-line react-hooks/exhaustive-deps


  const executeSearch = () => {
    if (!searchParams.name && !searchParams.location.term) {
      locationInput.current.focus();
    }
    locationSearchTermBounds.current = undefined;
    const params = interpretSearch(searchParams.name, searchParams.location.term);
    setUrlParams(params);
  }

  const getBrowserLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition((GeolocationPosition) => {
      let latitude: number = GeolocationPosition.coords.latitude;
      let longitude: number = GeolocationPosition.coords.longitude;
      new google.maps.Geocoder().geocode(
        { location: new google.maps.LatLng(latitude, longitude) },
        function (res: any[], status) {
          if (status === "OK" && res) {
            let postal_code: string = "";
            let secondary_postal_code: string = "";
            for (let i = 0; i < res.length; i++) {
              if (res[i].types[0] === "postal_code")
                postal_code = res[i].address_components[0].long_name
              if (res[i].types[0] === "route")
                for (let j = 0; j < res[i].address_components.length; j++) {
                  if (res[i].address_components[j].types[0] === "postal_code")
                    secondary_postal_code = res[i].address_components[j].long_name
                }
            }
            if (postal_code)
              setUrlParams(interpretSearch("", postal_code));
            if (secondary_postal_code)
              setUrlParams(interpretSearch("", secondary_postal_code));
          }
        }
      );
    });
  }

  const initAutocomplete = (): void => {
    //developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    let locationInputSearch: HTMLInputElement = document.querySelector("#search-box-input-location-input") as HTMLInputElement;
    autocomplete = new google.maps.places.Autocomplete(locationInputSearch, {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry", "formatted_address"],
    });
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (place?.formatted_address && place?.formatted_address?.length > 0)
      {
        const params = interpretSearch(nameInput.current.value, place.formatted_address);
        setSearchParams(params); //update search boxes with search terms on an autocomplete select
        setUrlParams(params); //update url with search terms and trigger search
      }
      else if (place?.name && place?.name?.length > 0)
      {
        const params = interpretSearch(nameInput.current.value, place.name);
        setSearchParams(params); //update search boxes with search terms on an autocomplete select
        setUrlParams(params); //update url with search terms and trigger search
      }
    });
  }

  const interpretSearch = (nameSearch: string, locationSearch: string): ISearchTerms => {
    let searchParams: ISearchTerms = { name: nameSearch, location: { term: locationSearch } };
    locationSearch = locationSearch.toUpperCase();

    if (locationSearch && locationSearch.length === 5 && locationSearch.match(/^\d{5}$/))
    {
      searchParams.location = { zip: locationSearch, term: locationSearch }
    }
    else
    {
      const stateAbbreviation = states.find(x => x.abbreviation === locationSearch || x.name === locationSearch)?.abbreviation;
      if (stateAbbreviation !== undefined && stateAbbreviation?.length > 0)
      {
        searchParams.location = { state: stateAbbreviation, term: stateAbbreviation };
      }
    }
    return searchParams;
  }

  //call agency search service using search box values or url parameters
  const findAgencies = async () => {
    setIsLoading(true);
    setError(false);
    let markers: { [key: string]: google.maps.Marker } = {};
    setMapMarkers(markers);
    let locationSearchTerm: string = urlParams.location.term;
    let nameSearchTerm: string = urlParams.name;

    let newSearchParams: ISearchTerms = {
      location: {
        term: locationSearchTerm,
        state: urlParams?.location?.state ?? searchParams?.location?.state,
        zip: urlParams?.location?.zip ?? searchParams?.location?.zip
      },
      name: nameSearchTerm
    }

    if (newSearchParams?.location?.state) {
      getResults(newSearchParams);
    }
    else if (locationSearchTerm) {
      if (locationSearchTermBounds.current) {
        newSearchParams.location.coordinates = locationSearchTermBounds.current;
        // getResults(newSearchParams);
      }
      else {
        try {
          //developers.google.com/maps/documentation/geocoding/requests-geocoding
          new google.maps.Geocoder().geocode({ address: locationSearchTerm }, //look up lat lng from google
            async (res, status) => {
              if (status === "OK") {
                newSearchParams.location.coordinates = {
                  lat: res[0].geometry!.location.lat(),
                  lng: res[0].geometry!.location.lng()
                };
                getResults(newSearchParams);
              }
              else if (status === "ZERO_RESULTS") { //if looking up lat lng errors, assume a bad search term and dont execute search
                results.current = [];
                setIsLoading(false);
                setError(true);
              }

            }
          );
        }
        catch (error) {
          console.error(`Geocoder Error: ${0}`,error)
        }
      }
    }
    else if (nameSearchTerm)
    {
      getResults({ name: nameSearchTerm, location: { term: "" } })
    }
  }

  //execute search, call a360 for elite agency statuses, render results
  const getResults = (obj: ISearchTerms) => {

    getSearchResults(obj).then(res => res.json()).then(data => {
      if (data) {
        data = data.sort((firstItem: any, secondItem: any) => parseFloat(firstItem.dis!) - parseFloat(secondItem.dis!));
        let agencyIDs: string[] = (data as any).map((x: any) => x.agencyID.substring(8));

        getEliteStatus(agencyIDs).then(res => res.json()).then(obj => {
          if (obj.errors) {
            results.current = data; //if a360 errors, still display results
            setIsLoading(false);
            return;
          }
          else if (obj.details.data) {

            let dict: { [agencyID: string]: boolean } = {}
            obj.details.data.forEach((el: any) => dict[el.AgencyNumber] = el.final_Tier == "Elite" ? true : false); //eslint-disable-line eqeqeq
            let eliteAgencies: any[3] = [];
            for (var i = 0; i < data.length; i++) {
              if (dict[data[i].agencyID.substring(8)]) {
                data[i].elite = true;
                if (eliteAgencies.length < 3) {
                  eliteAgencies.push(data[i]);
                  data.splice(i, 1);
                  i--;
                }
              }
            }

            for (var j = eliteAgencies.length - 1; j >= 0; j--)
              data.unshift(eliteAgencies[j]);

          }

          setCurrentPage(1);
          results.current = data;
          setIsLoading(false)
        },
          error => { //if a360 errors, still display results
            results.current = data;
            setIsLoading(false)
          })
      }
    },
      error => {
        setIsLoading(false);
        setError(true);
        results.current = [];
      })
  }

  //helper to create a map marker
  const createMarker = (agency: any, markerLabel: string) => {
    let lat = parseFloat(agency.lat);
    let lng = parseFloat(agency.lng);
    if (!lat || !lng)
      return;

    const marker = new google.maps.Marker({
      map,
      position: { lat: lat, lng: lng },
      label: { text: markerLabel, fontWeight: "bold" },
      title: formatAgencyName(agency.agencyPrimaryName),
      icon: Constants.icon
    } as google.maps.MarkerOptions);

    const agencyModal = (
      <AgencyInfoModal
        agencyID={agency.agencyID}
        blobId={agency.blobId}
        name={formatAgencyName(agency.agencyPrimaryName)}
        saveContext={saveContext}
        addressOne={fixCase(agency.agencyAddress1)}
        addressTwo={`${fixCase(agency.agencyCity)}, ${agency.agencyState} ${trimZipCode(agency.agencyZip)}`}
        email={agency.agencyEmail}
        website={agency.agencyWebsite}
        lat={lat}
        lng={lng}
        products={agency.products}
        languages={agency.languages}
        yearStarted={agency.yearStarted}
      />
    );

    marker.addListener("mouseover", () => {
      (window as any).zIndexIncrementor += 1;
      marker.setZIndex((window as any).zIndexIncrementor);
    });

    marker.addListener("click", () => {
      setMarker(lastClickedMarker, false);
      setMarker(marker, true);
      const content = ReactDOMServer.renderToString(agencyModal);
      infowindow.setContent(content);
      infowindow.open(map, marker);
      infowindow.addListener("closeclick", () => {
        setMarker(marker, false);
      })
      lastClickedMarker = marker;
    });
    return marker;
  }

  const setMarker = (marker: google.maps.Marker, active: boolean) => {
    if (active) {
      (window as any).zIndexIncrementor += 1;
      marker.setZIndex((window as any).zIndexIncrementor);
      marker.setIcon(Constants.activeIcon);
      marker.setLabel({ text: marker.getLabel()?.text || "", className: "map-marker", color: "white", fontWeight: "bold" });
    }
    else {
      marker.setZIndex((window as any).zIndexIncrementor - 1);
      marker.setIcon(Constants.icon);
      marker.setLabel({ text: marker.getLabel()?.text || "", color: "#1A1446", fontWeight: "bold" });
    }
  }

  const renderMap = () => {
    let records: any[] = language ? filteredResults : results.current;
    if (records?.length > 0) {
      map = new google.maps.Map(searchBoxMapRef.current as HTMLElement);
      google.maps.event.addListener(map, 'dragend', searchAfterPan);
      google.maps.event.addListener(map, 'bounds_changed', checkNewBounds);
      // google.maps.event.addListener(map, 'zoom_changed', searchAfterPan);

      let markersPos: google.maps.LatLng[] = [];
      let markers: { [key: string]: google.maps.Marker } = {};
      let bounds = new google.maps.LatLngBounds();
      for (let i = (currentPage * resultsPerPage - resultsPerPage); i < Math.min(currentPage * resultsPerPage, records.length); i++) {
        let marker = createMarker(records[i], Constants.labels[i % resultsPerPage]);
        if (marker) {
          let pos = marker.getPosition();
          if (pos) {
            markersPos.push(pos);
            markers[records[i].agencyID] = marker;
          }
        }
      }
      for (let i = 0; i < markersPos.length; i++) {
        bounds.extend(markersPos[i]);
      }
      if (map) {
        newMap.current = true;
        map.fitBounds(bounds);
        setMapMarkers(markers);
        SearchControlPosition.current = -1;
        UpdateCenterControl(map);
        panSearchThresholdMet.current = false;
        // checkNewBounds();
      }
    }
  }

  const UpdateCenterControl = (map: google.maps.Map) => {
    const centerControlDiv = document.createElement("div");
    CreateCenterControl(centerControlDiv, map);
    if (SearchControlPosition.current !== -1) {
      map.controls[google.maps.ControlPosition.TOP_LEFT].pop();
    }
    SearchControlPosition.current = map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
  }

  const CreateCenterControl = (controlDiv: Element, map: google.maps.Map) => {
    const controlUI = <div id="map-search-control-ui"
      onClick={toggleAutoSearch}>
      <div
        id="map-search-control-ui-text"
        title="Click to search the map area">
        Search map area
        <input
          id="map-search-control-ui-input"
          type="checkbox"
          checked={autoSearch.current}
          onChange={() => { return }}
        />
      </div>
    </div>;

    ReactDOM.render(controlUI, controlDiv);

    if (SearchControlPosition.current !== -1) {
    }
  }

  const toggleAutoSearch = () => {
    autoSearch.current = !autoSearch.current;
    setChecked(autoSearch.current);
    UpdateCenterControl(map);
    searchAfterPan();
  }

  useEffect(() => {
    if (map) {
      UpdateCenterControl(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, autoSearch]);


  const createOverLayProjection = () => {
    if (!overLay) {
      overLay = new google.maps.OverlayView();
    }

    overLay.draw = function () {
      projection = overLay.getProjection();
    }
    overLay.setMap(map);
  }

  const checkNewBounds = () => {
    if (map) {
      if (searchBoxMapRef?.current) {
        mapWidth.current = searchBoxMapRef?.current?.clientWidth ? searchBoxMapRef.current.clientWidth : 0;
        mapHeight.current = searchBoxMapRef?.current?.clientHeight ? searchBoxMapRef.current.clientHeight : 0;
      }

      if (newMap.current) {
        let bounds = map.getBounds();
        if (bounds) {
          mapBounds.current = bounds;
          newMap.current = false;
        }
      }

      let cPx = null;

      if (!overLay) {
        createOverLayProjection();
      }
      if (overLay) {
        if (projection) {
          cPx = projection.fromLatLngToContainerPixel(map.getCenter());
        }

        if (cPx !== null) {
          newCheckPanThresholdReached(cPx, mapWidth.current, mapHeight.current);
        }
      }
    }
  }

  const newCheckPanThresholdReached = (cpPx: google.maps.Point, gmapW: number, gmapH: number) => {
    if (projection) {
      let ne = mapBounds.current?.getNorthEast();
      let sw = mapBounds.current?.getSouthWest();

      if (ne && sw) {
        let neProjection = projection.fromLatLngToContainerPixel(ne);
        let swProjection = projection.fromLatLngToContainerPixel(sw);

        // lng (x) coords increase as you move right, lat (y) increase as you move north
        if (neProjection.x > cpPx.x + mapWidth.current || neProjection.x < cpPx.x) {
          panSearchThresholdMet.current = true;
        }
        else if (-swProjection.x > cpPx.x || swProjection.x > cpPx.x) {
          panSearchThresholdMet.current = true;
        }
        else if (neProjection.y > cpPx.y || -neProjection.y > cpPx.y) {
          panSearchThresholdMet.current = true;
        }
        else if (swProjection.y > cpPx.y + mapHeight.current || swProjection.y < cpPx.y) {
          panSearchThresholdMet.current = true;
        }
      }

    }
  }

  const searchAfterPan = () => {
    checkNewBounds();
    if (panSearchThresholdMet.current && autoSearch.current) {
      panSearchThresholdMet.current = false;
      locationSearchTermBounds.current = map.getCenter();

      let newSearchParams: ISearchTerms = {
        location: {
          term: locationSearchTermBounds.current.toString(),
          coordinates: locationSearchTermBounds.current.toJSON(),
          state: '',
          zip: ''
        },
        name: ''
      }

      setUrlParams(newSearchParams);

      getResults(newSearchParams);
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      locationSearchTermBounds.current = undefined;
      setUrlParams(searchParams);
    }
  };

  const getPagination = () => {
    return (<div className="pagination">
      {Math.ceil((language ? filteredResults : results.current).length / resultsPerPage) > 1 ? <>
        {currentPage === 1 ? null : <IconButton onClick={() => setCurrentPage(currentPage - 1)}><IconCaretCircleLeft title="Previous Pageg" size="32" /></IconButton>}
        {nearByPages.map((num, index) =>
          <span key={index}>
            {index !== 0 && nearByPages[index - 1] + 1 !== num ? <span>...</span> : null}
            <button className={num === currentPage ? "active num" : "num"} onClick={() => setCurrentPage(num)}>{num}</button>
            {index !== 4 && nearByPages[index + 1] - 1 !== num && nearByPages[index + 1] ? <span>...</span> : null}
          </span>
        )}
        {currentPage === Math.ceil((language ? filteredResults : results.current).length / resultsPerPage) ? null : <IconButton onClick={() => setCurrentPage(currentPage + 1)}><IconCaretCircleRight title="Next Page" size="32" /></IconButton>}
      </> : null} </div>);
  }

  const getMatchingAgent = (agentModel: any[]) => {

    if (!searchParams.name)
      return;
    for (var i=0; i<agentModel?.length; i++)
      if (agentModel[i].name.toUpperCase().indexOf(searchParams.name.toUpperCase()) > -1)
      {
        agentModel[i].name = agentModel[i].name.toLowerCase();
        return agentModel[i];
      }

  }
 const saveContext = () => {
  context.setSearchContext({ url: window.location.search, results: results.current, page: currentPage, filter: language });
 }
  return (
    <>
      {!hideLandingPageContent
        ? <div>
            <LandingPageContent />
        </div>
        : null
      }
      <HelmetProvider>
        <Helmet>
          <meta name="google-site-verification" content="E7pVc1-bRUYQyJCBLYTiEitwaXMZ8t_xUZ5k8R75oO4" />
        </Helmet>
      </HelmetProvider>
      <div id="search-box-banner" className={(language ? filteredResults : results.current).length ? "" : "non-sticky"}>
        <Form style={{ marginLeft: '0' }}>
          <FieldGroup isFull className="search-input-group">
            <GridRow id="search-box-inputs">
              {viewPortWidth && viewPortWidth > maxWidthTablet ?
                <Heading type="h2-light" className="white-text">Search Insurance Agencies</Heading>
                :
                <Heading type="h4-light" className="white-text">Search Insurance Agencies</Heading>
              }
            </GridRow>
            <GridRow id="search-box-row">
              <GridCol base={12} md={4}>
                <Link type="button" caretPosition="none" onBackground="blue" variant="standalone" className="get-browser-location" onClick={() => getBrowserLocation()}>Use current location</Link>
                <AlphanumericInput
                  id="search-box-input-location"
                  className="search-box-input-field"
                  labelVisual="Search by ZIP, City, Address"
                  value={formatSearchBoxValue(searchParams.location.term)}
                  onChange={(event) => setSearchParams({ name: searchParams?.name, location: { term: event.target.value } })}
                  onKeyDown={(e) => handleKeyDown(e)}
                  innerRef={locationInput}
                >
                  {searchParams.location.term
                    ? <IconButton
                      onClick={() => {
                        setSearchParams({ name: searchParams.name, location: { term: "" } }); locationInput.current.focus();
                      }}>
                      <IconClose size="16" />
                    </IconButton>
                    : null}
                </AlphanumericInput>
              </GridCol>
              <GridCol base={12} md={4} id="search-box-input-agency-col">
                <AlphanumericInput
                  id="search-box-input-agency"
                  className="search-box-input-field"
                  labelVisual="Search by Name"
                  value={searchParams?.name != null ? searchParams?.name : undefined} // if you update this, update the submit button's disabled attribute as well
                  onChange={(event) => {
                    setSearchParams({ name: event.target.value, location: searchParams?.location });
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                  innerRef={nameInput}
                >
                  {searchParams?.name
                    ? <IconButton
                      onClick={() => {
                        setSearchParams({ name: "", location: searchParams?.location }); nameInput.current.focus();
                      }}>
                      <IconClose size="16" />
                    </IconButton>
                    : null
                  }
                </AlphanumericInput>
              </GridCol>
              <GridCol base={12} md={2}>
                <Button
                  id="search-box-submit-button"
                  variant="primary"
                  onClick={() => executeSearch()}>Search
                </Button>
              </GridCol>
            </GridRow>
          </FieldGroup>
        </Form>
        <div id="search-results-top-row">
          <div className="top-row-pagination">
            {getPagination()}
          </div>
              {results.current.length === 0 ? null :
                <div>
                  {language ?
                    <div className="cancel-filter">
                      <div>
                        {languages[language]}
                      </div>
                      <IconButton onClick={() => {setTimeout(() => {window.scrollTo(0,109)},100);setLanguage(""); }}><IconClose size="16" /></IconButton>
                    </div>
                    : <>
                      <Select
                        className="language-filter"
                        isRequired
                        labelA11y="Language"
                        labelVisual="Language"
                        placeholderOption="Filter by language"
                        defaultValue=""
                        onChange={(event) => { setLanguage(event.currentTarget.value); window.scrollTo(0,109);} }
                      >
                        <SelectOption value="ARA">Arabic</SelectOption>
                        <SelectOption value="ARM">Armenian</SelectOption>
                        <SelectOption value="ASL">American Sign Language</SelectOption>
                        <SelectOption value="BSN">Bosnian</SelectOption>
                        <SelectOption value="CAM">Cambodian</SelectOption>
                        <SelectOption value="CAN">Cantonese</SelectOption>
                        <SelectOption value="CHI">Chinese</SelectOption>
                        <SelectOption value="DUT">Dutch</SelectOption>
                        <SelectOption value="FIJ">Fijian</SelectOption>
                        <SelectOption value="FRE">French</SelectOption>
                        <SelectOption value="GER">German</SelectOption>
                        <SelectOption value="GRK">Greek</SelectOption>
                        <SelectOption value="ITA">Italian</SelectOption>
                        <SelectOption value="JPN">Japanese</SelectOption>
                        <SelectOption value="KOR" >Korean</SelectOption>
                        <SelectOption value="MAN">Mandarin</SelectOption>
                        <SelectOption value="POL">Polish</SelectOption>
                        <SelectOption value="PTX">Portuguese</SelectOption>
                        <SelectOption value="RUS">Russian</SelectOption>
                        <SelectOption value="SCO">Serbo-Croatian</SelectOption>
                        <SelectOption value="SPA">Spanish</SelectOption>
                        <SelectOption value="TAG">Tagalog</SelectOption>
                        <SelectOption value="TAI">Taiwanese</SelectOption>
                        <SelectOption value="VIE">Vietnamese</SelectOption>
                      </Select>
                    </>
                  }
                </div>
              }
        </div>
      </div>
      <div id="search-box-results-wrapper-2" className={(language ? filteredResults : results.current).length ? "" : "hide-results"}>
      {isLoading || (context.searchContext && !results.current) ?
        <div className={results.current?.length > 0 ? "overlay loaded" : "overlay"}>
          <div>
            <CircularProgressSpinner aria-label="Loading search results" size="64" />
          </div>
        </div>
        : null
      }
        <div id="search-box-agency-results" >
          {(language ? filteredResults : results.current).map((agency: any, i: number) => i >= (currentPage * resultsPerPage - resultsPerPage) && i < currentPage * resultsPerPage
            ? <AgencyCard
              key={i}
              lat={agency.lat}
              lng={agency.lng}
              agencyID={agency.agencyID}
              agencyLocationId={agency.agencyLocationId}
              agencyLocationIndicator={agency.agencyLocationIndicator}
              distance={agency.dis}
              elite={agency.elite}
              saveContext={saveContext}
              name={formatAgencyName(agency.agencyPrimaryName)}
              modifiedDate = {agency.modifiedDate}
              agent={getMatchingAgent(agency.agentModel)}
              addressOne={fixCase(agency.agencyAddress1)}
              blobId={agency.blobId}
              addressTwo={`${fixCase(agency.agencyCity)}, ${agency.agencyState} ${trimZipCode(agency.agencyZip)}`}
              facebook={agency.Facebook}
              phone={`${agency.agencyAreaCode}${agency.agencyPhone}`}
              languages={agency.languages}
              website={agency.agencyWebsite?.trim() ? (agency.agencyWebsite.indexOf("http") !== -1 ? agency.agencyWebsite : `http://${agency.agencyWebsite}`) : null /*setting website protocol to http will redirect to https - some agency sites still use http*/}
              onMouseEnter={() => {
                let marker = mapMarkers[agency.agencyID];
                if (marker)
                  setMarker(marker, true);
              }}
              onMouseLeave={() => {
                let marker = mapMarkers[agency.agencyID];
                if (marker)
                  setMarker(marker, false);
              }}
            />
            : null)
          }
        </div>
        <div id="search-box-map" ref={searchBoxMapRef} className={(language ? filteredResults : results.current).length ? "" : "hide"}></div>
      </div>
      {getPagination()}
      {!isLoading && (language ? filteredResults : results.current).length === 0 && (urlParams.location.term || urlParams.name)
        ?
        <Card className="error-card">
          {error ? <Alert highlightType="negative" role="alert">{Constants.ErrorMessages.Default}</Alert> :
            language ? <Alert highlightType="negative" role="alert">{Constants.ErrorMessages.NoReultsWithFilter}</Alert> :
              <Alert highlightType="negative" role="alert">{Constants.ErrorMessages.NoResults}</Alert>}
        </Card>
        : null}
    </>
  );
}

export default SearchBoxContent;
