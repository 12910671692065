const getAgentInfo = async (
    agencyId: string | null,
    agentId: string | null,
  ) => {
    let pathPrefix = "/FAANG_App_Svc/resources/agentProfile/agentDetails?";
    let path = pathPrefix;
    if (agencyId && agentId) {
      path = `${pathPrefix}agencyId=${agencyId}&id=${agentId}`;
    }
    else{
        console.log("error: agency and agent did not have id")
    }
    return await fetch(path, {
      method: "GET",
    }).then((res) => {
      if (res.statusText === "Internal Server Error" || res.statusText === "No Content")
        return res;
      return res.json();
    }).catch(() => {                        // catch
      return {statusText:"Internal Server Error"};
    });
  };

export default getAgentInfo;