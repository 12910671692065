import React from "react";
import { Heading } from "@lmig/lmds-react";
import "./LandingPage.css";
import { Constants } from "../../helpers/Constants";

function LandingPageContent() {
  return (
    <>
      <div id="landing-page">
        <Heading id="landing-heading-1" type="h2">
          Find a Safeco Insurance Agent
        </Heading>
        <Heading id="landing-heading-2" type="h4">
          Buy Insurance Through an Independent Agent Today
        </Heading>
        <p id="landing-content-1">
         {Constants.defaultProfileText}
        </p>
        <p id="landing-content-2">
          Safeco policies are only available through independent agents because
          we believe in giving you a choice. Independent agents do, too. Find
          one using the search options below.
        </p>
      </div>
      {/* developers.google.com/search/docs/advanced/structured-data/local-business */}

      {/* TODO: image, priceRange fields - add good non-design system svg image- "image": ``, */}
      <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "InsuranceAgency",
            "name": `Safeco Insurance`,
            "description": `When you buy insurance through an independent agent, you get ease,
            choice, and advice. The ease of an insurance professional summarizing
            your options for you and helping you make sense of it all. The choice
            of many carriers so you can choose what fits you best. The advice of
            someone who isn't obligated to sell you one particular carrier or one
            particular type of insurance coverage.`,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": `1001 4th Ave`,
              "addressLocality": `Seattle`,
              "addressRegion": `WA`,
              "postalCode": `98104`,
              "addressCountry": "US"
            },
            "url": `https://insurance-agent.safeco.com/find-an-insurance-agency`,
            "telephone": `1-800-332-3226`,
          }
        )}
      </script>
    </>
  );
}

export default LandingPageContent;
