import {
    IconAuto,
    IconHome,
    IconRenters,
    IconLandlord,
    IconUmbrella,
    IconRV,
    IconCondo,
    IconMotorcycle,
    IconWatercraft
  } from "@lmig/lmds-react";

const Products = (props: {products: IProduct[]}) => {

  const filteredProducts = props.products.filter(product => {return product?.selected === undefined || product?.selected === true});
  return (filteredProducts ? <div id="about-section-products">
  {filteredProducts.map((product) => (
    <div className="agency-product" key={product.productId}>
      <div className="product-icon-wrapper">
        {product.productName === "Auto" ? <IconAuto size="32" /> : null}
        {product.productName === "Motorcycle" ? <IconMotorcycle /> : null}
        {product.productName === "Recreational Vehicle" ? <IconRV /> : null}
        {product.productName === "Home" ? <IconHome /> : null}
        {product.productName === "Renters" ? <IconRenters /> : null}
        {product.productName === "Condo" ? <IconCondo /> : null}
        {product.productName === "Landlord Protection" ? <IconLandlord /> : null}
        {product.productName === "Umbrella" ? <IconUmbrella /> : null}
        {product.productName === "Boat/Watercraft" ? <IconWatercraft /> : null}
      </div>
      <div>{product.productName}</div>
    </div>
  ))}
</div> : null)

}

export default Products;