const getSearchResults = (searchParams: ISearchTerms): Promise<Response> => {

  let path = "/FAANG_App_Svc/resources/api/agencies";

  if (searchParams?.location?.coordinates?.lat && searchParams.location?.coordinates.lng && searchParams.name)
    path+=`?lat=${searchParams?.location?.coordinates?.lat}&lng=${searchParams?.location?.coordinates?.lng}&name=${searchParams.name}&dis=100`;
  else if (searchParams?.location?.coordinates?.lat && searchParams.location?.coordinates.lng)
    path+=`?lat=${searchParams?.location?.coordinates?.lat}&lng=${searchParams?.location?.coordinates?.lng}&dis=100`;
  else if (searchParams?.name && searchParams?.location?.state)
    path+=`?name=${searchParams.name}&state=${searchParams.location.state}`;
  else if (searchParams?.location?.state)
    path+=`?state=${searchParams.location.state}`;
  else if (searchParams?.name)
    path+=`?name=${searchParams.name}`;
  else
    return Promise.reject("Incorrect Path");

  return fetch(path, {
    method: "GET",
  });
};
export default getSearchResults;
