import { Heading, Link } from "@lmig/lmds-react";
import "./LinksSubgroup.css";
function LinksSubgroup(props: {
  heading: string;
  links: { label: string; url: string }[];
}) {
  return (
    <div id="links-subgroup-container">
      <Heading tag="h2" type="h4-light">
        {props.heading}
      </Heading>
      {props.links.map((link) => (
        <div className="links-subgroup-link-wrapper" key={link.url}>
          <Link
            className="links-subgroup-link"
            href={link.url}
            variant="navigational"
          >
            {link.label}
          </Link>
        </div>
      ))}
    </div>
  );
}
export default LinksSubgroup;
