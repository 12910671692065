import "./AgentCard.css";
import { Link, IconEmail, IconPhone, Card, Heading, IconCaretDown, BodyText } from "@lmig/lmds-react";
import { formatPhone } from "../../helpers/StringFormatter";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { languages } from "../../helpers/LanguageHelper";

function AgentCard(props: any) {
  const [showSummary, setShowSummary] = useState<boolean>(false);

  return (
    <Card className="agent-card">
      <div className="agent-card-top-row">
        {/* agent basic info */}
        {props.coverPhotoBlobId ?
          <div className="profile-pic-container">
            <img
              id="agent-card-image"
              src={props.coverPhotoBlobId}
              alt={props.name}
              className="agent-card-profile-pic"
            />
          </div>
        : null}
        <div>
          {props.name ?
            <Heading type="h4">
              <RouterLink to={`/find-an-insurance-agency/agency/${props.agencyID}/agent/${props.agentID}`}>
              {props.name}
              </RouterLink>
            </Heading> 
          : ""}
          {props.phone ?
            <div>
              <Link
                caretPosition="none"
                variant="standalone"
                href={"tel:" + props.phone}><IconPhone size="16" color="teal" className="contact-link-icon" />{formatPhone(props.phone)}
              </Link>
            </div> 
          : null}
          {props.email ?
            <div>
              <Link caretPosition="none" variant="standalone" href={"mailto:" + props.email}>
                <IconEmail size="16" color="teal" className="contact-link-icon" />Email me</Link>
            </div> 
          : null}
        </div>
      </div>
      <div className="agent-card-bottom-row">
        <>
        {props.languages ?
          <BodyText>
            <h5>Additional languages:</h5>
            <BodyText>{props.languages.map((item: any, index: Number, array: any)=>{
              if(index === array.length -1){
                return languages[item.language_Code]
              }
              return languages[item.language_Code] + ", ";
              })}
            </BodyText>
          </BodyText> 
        : null}
        </>
        {props.yearStarted ? 
          <div>
            <h5 style={{display: 'inline'}}>Started: </h5>
            {props.yearStarted}
          </div> 
        : null}
          {/* show summary if present */}
        {props.summary ?
          <>
            <BodyText>
              <h5>Summary:</h5>
              {props.summary.length < 100 ? props.summary :
                <>
                  <div className={showSummary ? "agent-card-summary open-summary" : "agent-card-summary closed-summary"}>{props.summary}</div>
                  {!showSummary ? <Link onClick={() => setShowSummary(true)} variant="standalone"  caretPosition="none">Show more<IconCaretDown size="16" color="teal"/></Link> :
                  <Link onClick={() => setShowSummary(false)} variant="standalone"  caretPosition="none">Show less<IconCaretDown size="16" color="teal"/></Link>}
                </>
              }
            </BodyText>
          </> 
        : null}
      </div>
    </Card>
  );
}
export default AgentCard;
