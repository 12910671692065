import React from "react";
import "./TopFooterContent.css";
import LinksSubgroup from "../../shared/LinksSubgroup/LinksSubgroup";
import { resources } from "../TopFooterContent/ResourcesContent";
import { insuranceAgents } from "../TopFooterContent/InsuranceAgentsContent";
import { roadsideAssistance } from "../TopFooterContent/RoadsideAssistanceContent";
import { fileAClaim } from "../TopFooterContent/FileAClaimContent";
import { Link } from "@lmig/lmds-react";

function TopFooterContent() {
  return (
    <>
      <div id="top-footer">
          <LinksSubgroup 
            heading={resources.heading}
            links={resources.links} 
          />
          <LinksSubgroup
            heading={insuranceAgents.heading}
            links={insuranceAgents.links}
          />
          <LinksSubgroup
            heading={roadsideAssistance.heading}
            links={roadsideAssistance.links}
          />
          <LinksSubgroup
            heading={fileAClaim.heading}
            links={fileAClaim.links}
          />
      </div>
      <hr id="top-footer-horizontal-line"></hr>
      <div id="top-footer-2">
        <Link href="https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy">
          Privacy Policy
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.libertymutualgroup.com/about-lm/corporate-information/security-policy">
          Security Policy
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.safeco.com/terms-and-conditions">
          Terms and conditions
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.safeco.com/member-rights">Member rights</Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.safeco.com/underwriting-companies">
          Underwriting companies
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.safeco.com/producer-compensation-disclosure">
          Producer compensation disclosure
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.libertymutualgroup.com/about-lm/our-company/equal-employment-opportunity-and-anti-discrimination-policy">
          Equal opportunity employer
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.libertymutualgroup.com/about-lm/corporate-information/web-accessibility-policy">
          Web accessibility policy
        </Link>
        <span className="top-footer-divider"> | </span>
        <Link href="https://www.safeco.com/safeco-site-map">Site map</Link>
      </div>
    </>
  );
}
export default TopFooterContent;
