export const languages: { [key: string]: string } = {
  ARA: "Arabic",
  ARM: "Armenian",
  ASL: "American Sign Language",
  BSN: "Bosnian",
  CAM: "Cambodian",
  CAN: "Cantonese",
  CHI: "Chinese",
  DUT: "Dutch",
  FIJ: "Fijian",
  FRE: "French",
  GER: "German",
  GRK: "Greek",
  ITA: "Italian",
  JPN: "Japanese",
  KOR: "Korean",
  MAN: "Mandarin",
  POL: "Polish",
  PTX: "Portuguese",
  RUS: "Russian",
  SCO: "Serbo-Croatian",
  SPA: "Spanish",
  TAG: "Tagalog",
  TAI: "Taiwanese",
  VIE: "Vietnamese",
};

