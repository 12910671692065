import {
  IconButton, IconInfo, IconPeople, IconLocation, IconHomepage
} from "@lmig/lmds-react";
import "./MobileNav.css"

const MobileNav = (props: { showAgents: boolean }) => {
  const navigate = (heading: string) => {
    document.getElementById(heading)?.scrollIntoView({
      behavior: 'smooth', // smooth scroll
      block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
    })
  }
  return (<div id="mobile-nav">
    <IconButton textColor="white" onClick={() => navigate("faa-home")}><IconHomepage label="Home" size="16" /></IconButton>
    <IconButton textColor="white" onClick={() => navigate("about")}><IconInfo label="About" size="16" /></IconButton>
    {props.showAgents ? <IconButton textColor="white" onClick={() => navigate("our-team")}><IconPeople label="Agents" size="16" /></IconButton> : null}
    <IconButton textColor="white" onClick={() => navigate("office-location")}><IconLocation label="Location" size="16" /></IconButton>
  </div>)

}

export default MobileNav;