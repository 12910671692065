import { IconLocation, IconOffsite, IconEmail, Link } from "@lmig/lmds-react";
import { languages } from "../../helpers/LanguageHelper";
import "./AgencyInfoModal.css";
function InfoWindowContent(props: IAgencyInfoModal) {
  return (
    <div id={`im-${props.agencyID}`} className="agency-info-modal">
      <div>
        {/* Heading */}
        <Link variant="navigational" href={`/find-an-insurance-agency/agency/${props.agencyID}`} id="agency-info-modal-agency-name">{props.name}</Link>
      </div>
      {/* agency details */}
      <div id="agency-info-modal-top-wrapper">
        {props.blobId ?
          <>
            <img
              id="agency-info-modal-agency-image"
              src={"/FAANG_App_Svc/resources/blobs/" + props.blobId}
              alt={props.name} />
            <div className="agency-info-modal-padding" />
          </>
          : null}
        <div>
          <div className="agency-info-modal-address">
            <div>{props.addressOne}</div>
            <div>{props.addressTwo}</div>
          </div>
          <div id="agency-info-modal-subcategories-wrapper">
            {props.products ?
              <div className="agency-info-modal-subcategory">
                <div className="agency-info-modal-header">Products</div>
                {props.products.map((product, ind) => (
                  <>
                    {(ind === props.products.length - 1 && ind < 5) || ind === 4 ? product.productName + "..." : ind < 5 ? product.productName + ", " : ind > 5 ? null :
                      <Link variant="standalone" href={`/find-an-insurance-agency/agency/${props.agencyID}`}>See more </Link>
                    }
                  </>
                ))}
              </div>
              : null}
            {props.languages ?
              <div className="agency-info-modal-subcategory">
                <div className="agency-info-modal-header">Languages</div>
                {props.languages.filter((item,index) =>
                  props.languages?.indexOf(item)===index).map((language, ind) => (
                  <>
                    {ind === props.languages.length - 1 ? languages[language] : languages[language] + ", "}
                  </>
                ))}
              </div> : null}
            {props.yearStarted ?
              <div className="agency-info-modal-subcategory">
                <div className="agency-info-modal-header">Experience</div>
                {new Date().getFullYear() -
                  parseInt(props.yearStarted) +
                  " year(s) in the insurance business"}
              </div>
              : null}
          </div>
        </div>
      </div>
      {/* agency contact links */}
      <div id="agency-info-modal-contact-wrapper">
        {props.lat && props.lng ?
          <Link
            variant="standalone"
            caretPosition="none"
            className="agency-info-modal-icon-text-wrapper"
            href={`https://www.google.com/maps?saddr=Current+Location&daddr=${props.lat},${props.lng}`}
            target="_blank"
            rel="noopener noreferrer">
            <IconLocation
              className="agency-info-modal-contact"
              color="teal"
              size="16" />
            <div className="agency-info-modal-padding-2" />
            Get Directions
            <div className="agency-info-modal-padding" />
          </Link> : null}
        {props.email?.trim() ?
          <Link
            variant="standalone"
            caretPosition="none"
            className="agency-info-modal-icon-text-wrapper"
            href={"mailto:" + props.email}>
            <IconEmail
              className="agency-info-modal-contact"
              color="teal"
              size="16" />
            <div className="agency-info-modal-padding-2" />
            Email Us
            <div className="agency-info-modal-padding" />
          </Link> : null}
        {props.website?.trim() ?
          <Link
            variant="standalone"
            caretPosition="none"
            className="agency-info-modal-icon-text-wrapper"
            href={"https://" + props.website}
            target="_blank"
            rel="noopener noreferrer">
            <IconOffsite
              className="agency-info-modal-contact"
              color="teal"
              size="16"
            />
            <div className="agency-info-modal-padding-2" />
            Visit Website
          </Link> : null}
      </div>
    </div>
  );
}
export default InfoWindowContent;
