import { Heading } from "@lmig/lmds-react";
import { Constants } from "../../../helpers/Constants";
import Products from "../../Products/Products"
import "./AboutSection.css";

const AboutSection = (props: {
  summary: string;
  yearStarted: string;
  products: IProduct[];
  currentJobs?: IAgentJob[];
  pastJobs?:  IAgentJob[];
  educations?: IEducation[];
  languages?: { english_Language_Name: string }[]
  specialties: string;
  groups: { groupAndAssociationId: number; agentProfile_Id: number; name: string }[];
}) => {
  return (
    <>
    <Heading id="about" type="h3-light" className="about-section-heading">About me</Heading>
      {/* If there is information in the middle column of agency page, show the column */}
      {props.summary ||
      props.products ||
      props.currentJobs ||
      props.languages ||
      props.pastJobs ||
      props.educations ||
      props.yearStarted ? 
        
       <div className="agent-about-section">
      {props.summary || props.currentJobs || props.pastJobs || props.educations || props.groups || props.yearStarted ? 
        <div>
          <p>{props.summary?.toString()}</p>
          {props.yearStarted ? 
            <>
              <p> Agent Since: {props.yearStarted}</p>
            </> : null }

          {props.currentJobs ? 
            <>
              <Heading type="h4-light">Current</Heading>
              {props?.currentJobs.map((job) => (
                  <div>{job.title}, {job.company}</div>
              ))}
            </> : null}
          
          {props.pastJobs ? 
            <>
              <Heading type="h4-light">Past</Heading>
              {props?.pastJobs.map((job) => (
                  <div>{job.title}, {job.company}</div>
              ))}
            </> : null }

          {props.educations ? 
            <>
              <Heading type="h4-light">
                Education
              </Heading>
              {props.educations.map(education => 
                <div>{education.schoolName}</div>
              )}
            </> : null }

          {props.specialties ? 
            <>
              <Heading type="h4-light">
                Specialties
              </Heading>
              <p>{props.specialties}</p>
            </> : null }

          {props.groups ? 
            <>
              <Heading type="h4-light">
                Groups and associations
              </Heading>
              {props.groups.map((group) =>
                <div>{group.name}</div>
              )}
            </> : null }
        </div> : null
      }
        <div className="about-section-column">
          {props.languages ? 
            <>
              <Heading type="h4-light">Languages</Heading>
              <p>{props.languages.map(language => language.english_Language_Name.trim()).join(", ")}</p>
            </> : null}
          {props.products ? 
            <>
              <Heading type="h4-light">
                Products I specialize in
              </Heading>
              <Products products={props.products}/>
            </> : null }
        </div>
        </div> : <p>{Constants.defaultProfileText}</p>}
    </>
  );
}
export default AboutSection;
