import React from "react";
import "./BottomFooterContent.css";
import {
  IconFacebook,
  IconLinkedin,
  IconTwitter,
  LogoSafecoVert,
} from "@lmig/lmds-react";

function BottomFooterContent() {
  let currentYear = new Date().getFullYear();
  return (
    <div id="footer-outer-container">
      <div id="footer-icons">
        <div id="footer-safeco-logo">
          <LogoSafecoVert color="inverse" />
        </div>
        <div id="footer-icon-wrapper">
          <a
            href="https://www.facebook.com/SafecoInsurance"
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconFacebook id="footer-icon-facebook" color="inverse" size="16" />
          </a>
          <a
            href="https://www.linkedin.com/company/safeco-insurance"
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconLinkedin id="footer-icon-linkedin" color="inverse" size="16" />
          </a>
          <a
            href="https://twitter.com/SafecoInsurance"
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconTwitter id="footer-icon-twitter" color="inverse" size="16" />
          </a>
        </div>
      </div>
      <div>
        <p className="footer-text">
          &copy; {currentYear} Liberty Mutual Insurance Company, 175 Berkeley
          Street, Boston, MA 02116
        </p>
        <p className="footer-text">
          Insurance is offered by Safeco Insurance Company of America and/or its
          affiliates, with their principal place of business at 175 Berkeley
          Street, Boston, Massachusetts, 02116. This website provides a
          simplified description of coverage. Nothing stated herein creates a
          contract. All statements made are subject to the provisions,
          exclusions, conditions and limitations of the applicable insurance
          policy. Please refer to actual policy forms for complete details
          regarding the coverage discussed. If the information in these
          materials conflicts with the policy language that it describes, the
          policy language prevails. Coverages and features not available in all
          states. Eligibility is subject to meeting applicable underwriting
          criteria.
        </p>
      </div>
    </div>
  );
}
export default BottomFooterContent;
