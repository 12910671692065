import { Card, Heading } from "@lmig/lmds-react";
import "./InfoCard.css";

function InfoCard(props: {
  title: string;
  content?: string;
  children?: React.ReactNode;
}) {
  return (
    <Card className="info-card">
      <Heading type="h4-light">{props.title}</Heading>
      <p>{props.content}</p>
      <div>{props.children}</div>
    </Card>
  );
}
export default InfoCard;
