const claimLinks = [
    {
      label: "1-800-332-3226",
      url: "tel:1-800-332-3226",
    },
    {
      label: "File your claim online",
      url: "https://customer.safeco.com/accountmanager/homepage?OriginalURL=https://eclaims.safeco.com/report/claimType",
    },
  ];
  
  const fileAClaim = { heading: "File a Claim", links: claimLinks };

  export {fileAClaim};