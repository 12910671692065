import { LogoSafecoHoriz, Link, IconButton, IconMenu } from "@lmig/lmds-react";
import "./ResponsiveHeader.css";

function ResponsiveHeader(props: { setShowSideBar: (show: boolean) => void }) {
  return (
    <>
      <a href="https://safeco.com">
        <LogoSafecoHoriz />
      </a>
      <div id="header-responsive-nav-wrapper">
        <Link variant="inline">Log In</Link>

        <IconButton
          id="header-responsive-nav-menu"
          onClick={() => props.setShowSideBar(true)}
          background="transparent"
        >
          <IconMenu size="16" />
        </IconButton>
      </div>
    </>
  );
}
export default ResponsiveHeader;
