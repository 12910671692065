import { useEffect } from "react";

const useOutsideClick = (ref:React.RefObject<HTMLDivElement>, callback:()=>void, capture=false) => {
  const handleClick = (e:any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, capture);

    return () => {
      document.removeEventListener("click", handleClick, capture);
    };
  });
};

export default useOutsideClick;