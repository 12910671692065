const resourcesLinks = [
  {
    label: "Log in",
    url: "https://customer.safeco.com/accountmanager/account/login",
  },
  {
    label: "Create your account",
    url: "https://customer.safeco.com/accountmanager/account/createnew",
  },
  {
    label: "Customer Support",
    url: "https://www.safeco.com/customer-resources/customer-support",
  },
  {
    label: "Pay your bill",
    url: "https://customer.safeco.com/accountmanager/billing/payment",
  },
  {
    label: "Fraud alert",
    url: "https://www.safeco.com/fraud-alert",
  },
  {
    label: "Careers",
    url: "https://www.safeco.com/careers",
  },
];

const resources = { heading: "Resources", links: resourcesLinks };

export {resources};