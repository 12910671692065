import { ReactNode } from "react"
import {
  LogoSafecoHoriz,
  IconButton,
  IconClose,
} from "@lmig/lmds-react";
import "./SlidePanel.css";

function SlidePanel(props: {
  onDismiss: () => void;
  children?: ReactNode
}) {
  return (
    <>
      <div id="background" onClick={props.onDismiss}></div>
      <div id="slide-panel-wrapper">
        <div id="slide-panel-logo-x-wrapper">
          <LogoSafecoHoriz id="slide-panel-icon" />
          <IconButton
            id="slide-panel-x"
            onClick={props.onDismiss}
            background="transparent"
          >
            <IconClose size="16" />
          </IconButton>
        </div>
        {props.children}
      </div>
    </>
  );
}
export default SlidePanel;
