import { Heading, Link, IconLocation } from "@lmig/lmds-react";
import { useState, useEffect } from "react";
import "./OfficeLocation.css";

function OfficeLocation(props: IOfficeLocation) {
  let [staticMaps, setStaticMaps] = useState<{ [officeId: string]: { lat?: string, lng?: string, src: string } }>({});
  const [primaryMapLoaded, setPrimaryMapLoaded] = useState<boolean>(false)
  const [mapsLoaded, setMapsLoaded] = useState<boolean[]>([]);

  useEffect(() => {
    if (props.primaryOffice.lat && props.primaryOffice.lng) {
      fetch(`/FAANG_App_Svc/resources/googleapi/staticmap?lat=${props.primaryOffice.lat}&lng=${props.primaryOffice.lng}&width=326&height=324`, {
        method: 'GET'
      })
        .then(res => res.text())
        .then(data => {
          setStaticMaps(staticMaps => ({
            ...staticMaps, // eslint-disable-next-line
            ["primary"]: { src: data }
          }));
        })
    }

  }, [props.primaryOffice]);

  useEffect(() => {
    if (props.otherOffices) {
      for (var i = 0; i < props.otherOffices.length; i++) {
        let office = props.otherOffices[i];
        new google.maps.Geocoder().geocode({ address: `${office.adressLine1}, ${office.city}, ${office.state} ${office.zip}` }, //look up lat lng from google
          async (results, status) => {
            if (status === "OK") {
              fetch(`/FAANG_App_Svc/resources/googleapi/staticmap?lat=${results[0].geometry!.location.lat()}&lng=${results[0].geometry!.location.lng()}&width=326&height=324`, {
                method: 'GET'
              })
                .then(res => res.text())
                .then(data => {
                  setStaticMaps(staticMaps => ({
                    ...staticMaps,
                    [office.officeId]: { lat: results[0].geometry!.location.lat(), lng: results[0].geometry!.location.lng(), src: data }
                  }));
                })
            }
          }
        );
      }
    }

  }, [props.otherOffices]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="maps-and-hours-container" id="maps-and-hours">
      {/* show static map for given lat and lng */}
      {props.primaryOffice.lat && props.primaryOffice.lng ?
        <div>
          <Heading id="office-location" type="h3-light" className="maps-and-hours-heading">Our office location</Heading>
          <div>
            <div className="office-name">{props.primaryOffice.officeName}</div>
            <div>{props.primaryOffice.addressLine1}</div>
            <div>{props.primaryOffice.addressLine2}</div>
            <Link
              variant="standalone"
              id="maps-and-hours-directions"
              href={
                `https://www.google.com/maps?saddr=Current+Location&daddr=${props.primaryOffice.lat},${props.primaryOffice.lng}`}
              target="_blank"
              caretPosition="none"
              rel="noopener noreferrer"><IconLocation size="16" />Get directions</Link>
          </div>
          <div className={primaryMapLoaded ? "maps-container" : "maps-placeholder"}>
            <a className="maps-img" href={`https://www.google.com/maps?saddr=Current+Location&daddr=${props.primaryOffice.lat},${props.primaryOffice.lng}`} target="_blank" rel="noreferrer">
              <img
                className="maps-img"
                object-fit
                alt="Agency location on google maps"
                src={staticMaps["primary"]?.src}
                onLoad={() => setPrimaryMapLoaded(true)}
                /></a>
          </div>
        </div>
        : null}

      {/* other office locations and directions if present */}
      {props.otherOffices ?
        <div>
          <Heading type="h4-light" className="maps-and-hours-heading"> Other office locations</Heading>
          {props.otherOffices.map((office, i) =>
            <div className="other-office-location" key={i}>
              <div className="office-name">{office.officeName}</div>
              <div>{office.adressLine1}</div>
              <div>{`${office.city}, ${office.state} ${office.zip}`}</div>
              <Link
                variant="standalone"
                id="maps-and-hours-directions"
                caretPosition="none"
                href={`https://www.google.com/maps?saddr=Current+Location&daddr=${staticMaps[office.officeId]?.lat},${staticMaps[office.officeId]?.lng}`}
                target="_blank"
                rel="noopener noreferrer"><IconLocation size="16" />Get directions</Link>
                <div className={mapsLoaded[i] === true ? "maps-container" : "maps-placeholder"}>
                  <a className="maps-img" href={`https://www.google.com/maps?saddr=Current+Location&daddr=${staticMaps[office.officeId]?.lat},${staticMaps[office.officeId]?.lng}`} target="_blank" rel="noreferrer">
                    <img id="maps-and-hours-map"
                      className="maps-img"
                      object-fit
                      alt="Agency location on google maps"
                      src={staticMaps[office.officeId]?.src}
                      onLoad={()=>{
                        let temp = mapsLoaded;
                        mapsLoaded[i] = true;
                        setMapsLoaded(temp)}}
                      /></a>
                </div>
            </div>
          )}
        </div> : null}


    </div>
  );
}
export default OfficeLocation;
