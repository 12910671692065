
const getEliteStatus = (agencyIDs: string[]): Promise<Response> => {

    if (agencyIDs.length > 0) {
        let url: string = `/api/agency/infobystatcodes?statcode=${agencyIDs[0]}`
        for (var i = 1; i < agencyIDs.length; i++) {
          url += `&statcode=${agencyIDs[i]}`
        }
        return fetch(url, {
          method: 'GET'
        })
      }
      return Promise.reject();
  };
  export default getEliteStatus;
  