import "./AgencyCard.css";
import { IconPhone, Heading, Link, Modal, IconButton, IconAgent, BodyText } from "@lmig/lmds-react";
import { formatPhone } from "../../helpers/StringFormatter";
import React, { useState, useEffect, useContext } from "react";
import Reviews from "../Reviews/Reviews"
import { Link as RouterLink } from "react-router-dom";
import { findPlaceFromPhoneNumber } from "../../apiCalls/googleServices";
import { AppContext } from "../../App";
import { languages } from "../../helpers/LanguageHelper";
import getAgencyInfo from "../../apiCalls/getAgencyInfo";
import eliteBadge from "./assets/Ignite-White-Rocket.svg";

function AgencyCard(props: IAgency) {
  const [reviews, setReviews] = useState<undefined | google.maps.places.PlaceResult>();
  const [isOpen, setIsOpen] = useState(false);
  const [showGoogleReviews, setShowGoogleReviews] = useState(true);
  const context = useContext(AppContext);

  const updatePlaceid = () => {
    context.setPlace(reviews);
  }

  useEffect(() => {
    async function getRatings() {
      let res = await getAgencyInfo(props.agencyID);
      if (res 
          && !(res.statusText === "Internal Server Error" || res.statusText === "No Content")
          && !(res?.agencyModel?.showGoogleReviews)
          )
      {
        setReviews(undefined);  
        setShowGoogleReviews(false)
      }

      else
      {
        let data = await findPlaceFromPhoneNumber(`${props.phone}`, new google.maps.LatLng(props.lat, props.lng)) as google.maps.places.PlaceResult[];
        //let data = await findPlaceFromQuery(`${props.name} ${props.addressOne} ${props.addressTwo} insurance-agency`, new google.maps.LatLng(props.lat,props.lng)) as google.maps.places.PlaceResult[];
        for (var i = 0; i < data.length; i++) {
          if (data[i] && data[i].formatted_address?.split(' ')[0] === props.addressOne.split(' ')[0]) {
            if (data[i].rating && data[i].user_ratings_total)
              setReviews(data[i]);
          }
        }
      }
    }
    getRatings()
  }, [props.phone]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="agency-card-wrapper"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}>
      <div id="agency-card-agency-info">
        <div className="agency-title-row">
          <Heading type="h4-light">
            <RouterLink
              className="agency-card-link"
              onClick={() => {updatePlaceid(); props.saveContext()}}
              to={`/find-an-insurance-agency/agency/${props.agencyID}`}>
              {props.name}
            </RouterLink>
          </Heading>
          <span className="hide">Agency Location Id: {props.agencyLocationId}<br />Agency profile modified date: {props.modifiedDate}<br /> Agency Physical Location Indicator: {props.agencyLocationIndicator}</span>
        </div>
      </div>
      <div id="agency-card-image-info-wrapper">
        <div className="agency-card-contact-info-row">
          <div className="agency-card-address">
            {props.elite
              ? <div id="elite-image">
                  <div>Safeco Elite Agency</div>
                  <img src={eliteBadge} alt="Safeco Elite Agency">
                  </img>
                </div>
              : null
            }
            <div className="agent-name-row">
              <div>
                <Link
                  className={"agency-card-address-link"}
                  target="_blank"
                  // use this if want to pass in placeid, however the place id doesn't seem to increase accuracy for our case 
                  // href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(`${props.name.trim()} ${props.addressOne.trim()} ${props.addressTwo.trim()}`)}${placeID ? `&query_place_id=${placeID}` : ``}`}
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(`${props.name.trim()} ${props.addressOne.trim()} ${props.addressTwo.trim()}`)}`}
                  title={"Click for directions"}
                  variant="standalone"
                  caretPosition="none"
                >
                  <div>{props.addressOne}</div>
                  <div>{props.addressTwo}</div>
                </Link>
                <div className="agency-card-phone-number">
                  <Link variant="standalone" caretPosition="none" href={`tel:${props.phone}`}>
                    <IconPhone size="16" color="teal" />{formatPhone(props.phone)}
                  </Link>
                </div>
                {props.website?.trim()
                  ? <div className="agency-card-website">
                    <Link href={props.website} target="_blank" variant="standalone" caretPosition="none">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="18px" height="18px" viewBox="0 0 24.000000 24.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" fill="#06748c" stroke="none"><path d="M94 213 c-66 -12 -92 -110 -42 -159 37 -37 99 -37 136 0 68 67 3 177 -94 159z m106 -88 c0 -56 -33 -76 -50 -30 -7 19 -17 25 -40 25 -36 0 -37 9 -4 35 14 11 31 25 37 32 16 16 57 -29 57 -62z m-105 -48 c18 -36 18 -37 3 -37 -24 0 -58 43 -58 73 0 30 0 30 20 12 11 -10 27 -32 35 -48z"></path></g></svg>
                      Visit Website
                    </Link>
                  </div>
                  : null
                }
              </div>
              {props.agent
                ? <RouterLink to={`/find-an-insurance-agency/agency/${props.agencyID}/agent/${props.agent.agentID}`} onClick={() => props.saveContext()}>
                    <IconButton hasOutline>
                      <IconAgent className="agent-icon" label={props.agent.name} />
                    </IconButton>
                  </RouterLink>
                : null
              }
            </div>
            <>
            {props.languages
              ? <BodyText>
                <div id="agency-card-agency-info"><h5>Additional languages: </h5></div>
                <BodyText style={{whiteSpace:"nowrap", overflow: "hidden", textOverflow:"ellipsis", marginRight:"3rem"}}>{props.languages.filter((item,index) =>
                  props.languages?.indexOf(item)===index).map((item, index, array)=>{
                      if(index === array.length -1){
                        return languages[item];
                      }
                      return languages[item] + ", ";
                  })}</BodyText>
              </BodyText> 
              : null
            }
            </>
            <div className="agency-card-social-column">
              {reviews && showGoogleReviews
                ? <>
                    <div
                      className="google-reviews" 
                      onClick={() => setIsOpen(true)}
                    >
                      <span className="rating stars" style={{"--rating" : reviews?.rating ?? 0 } as React.CSSProperties}>
                          {reviews.rating?.toPrecision(2)}&nbsp;&nbsp;<span></span>
                      </span>
                      <Link caretPosition="none" variant="standalone">{`${reviews.user_ratings_total} review${reviews.user_ratings_total !== 1 ? 's' : ''}`}</Link>
                    </div>

                    <Modal
                      isOpen={isOpen}
                      size="large"
                      modalStyle="standard"
                      className="reviews-modal"
                      title={
                        <div className="reviews-modal-title-row">
                          <div>
                            <div>{props.name}</div>
                          </div>
                          <div className="modal-page-reviews agency-page-reviews" >
                            <small>Reviews powered by Google</small>
                            <div>
                              <span className="rating stars" style={{"--rating" : reviews?.rating ?? 0 } as React.CSSProperties}>
                                {reviews.rating?.toPrecision(2)}&nbsp;&nbsp;
                              </span>
                            </div>
                            {props.lat && props.lng && reviews?.place_id ? <a rel="noreferrer" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lng}&query_place_id=${reviews?.place_id}`}><small>{`${reviews?.user_ratings_total} review${reviews?.user_ratings_total !== 1 ? 's' : ''}`}</small></a> : null}
                          </div>
                        </div>
                      }
                      closeFunc={() => setIsOpen(false)}
                    >
                      {isOpen && reviews?.place_id
                        ? <Reviews {... { placeid: reviews.place_id, 
                                          lat: props.lat, 
                                          lng: props.lng 
                                        }
                                    } 
                          />
                        : null
                      }
                    </Modal>
                  </>
                : null
              }
              {/* facebook like button https://developers.facebook.com/docs/plugins/like-button/ */}
              {props.facebook ? <iframe title={props.name} src={`https://www.facebook.com/plugins/like.php?href=${props.facebook}&width=108&layout=button_count&action=like&size=small&share=false&height=21&appId`} width="108" height="21" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                : null}
            </div>
          </div>
          <div className="image-column">
            {props.blobId
              ? <img
                className="agency-card-image"
                src={`/FAANG_App_Svc/resources/blobs/${props.blobId}`}
                alt=""
              />
              : null
            }
            <div className="agency-card-marker-column">
              <div title={"Distance is from center of search"}>
                <div className="agency-card-map-pin">
                  <svg width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="svg-inline--fa fa-map-marker fa-w-12 fa-5x">
                    <path stroke="#06748c" fill="#99E5EA" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>
                </div>
                {props.distance ? <span className="agency-card-distance">{`${(Math.round(props.distance * 10) / 10).toPrecision(2)}`.substring(0, 3) + " mi"}</span> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyCard;
