import "./HeaderContent.css";
import { useState } from "react";
import ResponsiveHeader from "./ResponsiveHeader/ResponsiveHeader";
import RegularHeader from "./RegularHeader/RegularHeader";
import SlidePanelContent from "./ResponsiveHeader/SlidePanelContent/SlidePanelContent"
import SlidePanel from "../shared/SlidePanel/SlidePanel";
import { Constants } from "../../helpers/Constants";
import InfoCard from "./InfoCard/InfoCard";
import { Button, Disclaimer, Link } from "@lmig/lmds-react";

const HeaderContent = (props: { squeeze: boolean }) => {
  
  const [showResponsiveHeader, setShowResponsiveHeader] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);


  window.onresize = window.onload = function () {
    handleResponsiveness();
  };
  function handleResponsiveness() {
    if (window.outerWidth < 768) {
      setShowResponsiveHeader(true);
    } else {
      setShowResponsiveHeader(false);
      setShowSideBar(false);
    }
  }
  function shopInsuranceInfoCard() : JSX.Element {
    return <InfoCard title={"Safe drivers save with RightTrack®"}>
    <a href="https://www.safeco.com/products/righttrack">
      <Button>Learn how</Button>
    </a>
  </InfoCard>
  }
  function forAgentsInfoCard() : JSX.Element {
    return <InfoCard title="Get Roadside Assistance*">
    <>
      <Link href="tel:1-877-762-3101" variant="standalone" caretPosition="none" style={{ marginBottom: "0.75rem" }}>
        1-877-762-3101
      </Link>
      <Disclaimer>
        *Additional charges apply if your policy does not include the Towing and
        Labor coverage.
      </Disclaimer>
    </>
  </InfoCard>
  }
  function forCustomersInfoCard() : JSX.Element {
    return <InfoCard title="Apply to sell Safeco Insurance" content="We offer unique product lines and competitive commisions.">
    <a href="https://www.safeco.com/agent-resources/apply"><Button>Apply now</Button></a>
  </InfoCard>
  }
  return (
    <div id="header-container" className={ props.squeeze ? "squeeze-header" : ""}>
      {/* determine which type of header should be shown */}
      {!showResponsiveHeader ? <RegularHeader {...[shopInsuranceInfoCard(), forCustomersInfoCard(), forAgentsInfoCard()]} /> : <ResponsiveHeader setShowSideBar={setShowSideBar} />}
      {/* used in responsive header to show the links */}
      {showSideBar ? (
        <SlidePanel
          onDismiss={() => {
            setShowSideBar(false);
          }}>{<SlidePanelContent content={{
            shopInsuranceContent:Constants.MegaMenus.ShopInsurance,
            forCustomersContent:Constants.MegaMenus.ForCustomers,
            forAgentsContent:Constants.MegaMenus.ForAgents,
            infoCards:[shopInsuranceInfoCard(), forCustomersInfoCard(), forAgentsInfoCard()]
          }}/>}</SlidePanel>

      ) : null}
    </div>
  );
}
export default HeaderContent;
