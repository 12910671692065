import "./ReviewCard.css";
import ReviewCard from "./ReviewCard"
import { useState, useEffect } from "react";
import {Card} from "@lmig/lmds-react";
import { getPlaceDetails } from "../../apiCalls/googleServices"

function Reviews(props: { placeid: string, lat?: number, lng?: number }) {
    let [placeDetails, setPlaceDetails] = useState<google.maps.places.PlaceReview[]>();
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getReviews() {
            if (!placeDetails) {
                setIsLoading(true);
                setPlaceDetails(await getPlaceDetails(props.placeid) as google.maps.places.PlaceReview[]);
                setIsLoading(false);
            }
        }
        getReviews();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderPlaceholders = () =>{
        return(<>
            <Card className="placeholder-review"/>
            <Card className="placeholder-review"/>
            <Card className="placeholder-review"/>
            <Card className="placeholder-review"/></>)
    }

    return (
        <>
            {isLoading ? <div className="overlay">
                {renderPlaceholders()}
            </div> : <></>}
            {placeDetails ? placeDetails.map((review: google.maps.places.PlaceReview, i) => (
                <ReviewCard key={i} {...review} />
            )) : null}

        </>
    );
}
export default Reviews;
