import { Heading, IconCaretRight } from "@lmig/lmds-react";
import { Link as RouterLink } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../App";
import "./Breadcrumb.css"

function Breadcrumb(props: { searchResults: string, agencyName?: string, agencyID?:string, currentPage: string }) {

    const context = useContext(AppContext);

    return (
        <Heading type="h6" className="faa-breadcrumb">
            <RouterLink 
                to={context?.searchContext?.url
                        ? `/find-an-insurance-agency${context?.searchContext?.url}`
                        : "/find-an-insurance-agency"
                    }
                className="search-results-link"
            >
                Search results
                <IconCaretRight 
                    size="16" 
                    style={{ marginLeft: '10px', marginRight: '10px' }} 
                />
            </RouterLink>
            {props.agencyName && props.agencyID
                ? <RouterLink 
                    to={`/find-an-insurance-agency/agency/${props.agencyID}`}
                >
                    {props.agencyName}
                    <IconCaretRight
                        size="16" 
                        style={{ marginLeft: '10px', marginRight: '10px' }} 
                    />
                    </RouterLink>
                : null
            }
            <p>{props.currentPage}</p>
        </Heading>
    );
}
export default Breadcrumb;
